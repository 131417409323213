import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building21 = ({ unitClass, click }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 21" });
  return (
    <g id="BUILDING-21">
      <g
        onClick={() => click("F1")}
        className={unitClass(b, "29")}
        id="UNIT-29-F1"
      >
        <polygon
          className={S.Hot}
          points="5013.133,1115.165 5013.133,1019.924 4922.905,1019.924 4922.905,1180.101 4922.905,1193.544
				4922.905,1206.076 4975.538,1206.076 4975.538,1193.544 4992.626,1193.544 4992.626,1115.165 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4946.25 1108.2363)"
            className={S.Number}
          >
            29
          </text>
          <text
            transform="matrix(1 0 0 1 4949.5645 1137.6154)"
            className={S.Plan}
          >
            F1
          </text>
        </g>
      </g>
      <g
        onClick={() => click("G")}
        className={unitClass(b, "30")}
        id="UNIT-30-G"
      >
        <polygon
          className={S.Hot}
          points="5013.133,1019.924 5013.133,1115.165 4992.626,1115.165 4992.626,1193.544 5015.412,1193.544
				5015.412,1219.291 5026.12,1219.291 5026.12,1202.658 5065.538,1202.658 5065.538,1019.924 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5021.8901 1108.2363)"
            className={S.Number}
          >
            30
          </text>
          <text
            transform="matrix(1 0 0 1 5029.1426 1137.6154)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g
        onClick={() => click("F")}
        className={unitClass(b, "31")}
        id="UNIT-31-F"
      >
        <polygon
          className={S.Hot}
          points="5157.589,1114.253 5157.589,1019.924 5065.538,1019.924 5065.538,1202.658 5065.538,1206.076
				5118.854,1206.076 5118.854,1193.544 5138.677,1193.544 5138.677,1114.253 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5091.498 1108.2363)"
            className={S.Number}
          >
            31
          </text>
          <text
            transform="matrix(1 0 0 1 5096.1499 1137.6154)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g
        onClick={() => click("G")}
        className={unitClass(b, "32")}
        id="UNIT-32-G"
      >
        <path
          className={S.Hot}
          d="M5209.538,1019.924h-51.949v94.329h-18.912v79.291h20.962v25.747h8.658v-16.633h41.241
				C5209.538,1197.646,5209.538,1019.924,5209.538,1019.924z"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5167.561 1108.2363)"
            className={S.Number}
          >
            32
          </text>
          <text
            transform="matrix(1 0 0 1 5173.3706 1137.6154)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g
        onClick={() => click("F")}
        className={unitClass(b, "33")}
        id="UNIT-33-F"
      >
        <path
          className={S.Hot}
          d="M5298.399,1113.797v-93.873h-88.861c0,0,0,177.722,0,182.734v2.279h52.405v-11.392h18.228v-79.747H5298.399z
				"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5233.7314 1108.2361)"
            className={S.Number}
          >
            33
          </text>
          <text
            transform="matrix(1 0 0 1 5241.27 1137.6151)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g
        onClick={() => click("G1")}
        className={unitClass(b, "34")}
        id="UNIT-34-G1"
      >
        <polygon
          className={S.Hot}
          points="5298.399,1019.924 5298.399,1113.797 5280.171,1113.797 5280.171,1193.544 5302.728,1193.544
				5302.728,1218.38 5311.614,1218.38 5311.614,1204.025 5354.449,1204.025 5354.449,1193.544 5354.449,1019.924 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5310.3525 1108.2362)"
            className={S.Number}
          >
            34
          </text>
          <text
            transform="matrix(1 0 0 1 5312.4053 1137.6152)"
            className={S.Plan}
          >
            G1
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building21;
