import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building10 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 10" });
  return (
    <g id="BUILDING-10">
      <g className={unitClass(b, "108")} id="UNIT-108-G1">
        <polygon
          className={S.Hot}
          points="4345.994,2106.377 4422.551,2106.377 4422.551,2086.202 4434.095,2086.202 4434.095,2036.278
				4249.386,2036.278 4249.386,2086.202 4345.994,2086.202 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4364.4839 2067.1138)"
            className={S.Number}
          >
            108
          </text>
          <text
            transform="matrix(1 0 0 1 4372.3872 2096.4932)"
            className={S.Plan}
          >
            G1
          </text>
        </g>
      </g>
      <g className={unitClass(b, "109")} id="UNIT-109-F">
        <polygon
          className={S.Hot}
          points="4434.095,2128.025 4422.551,2128.025 4422.551,2106.377 4345.994,2106.377 4345.994,2086.202
				4249.386,2086.202 4249.386,2178.456 4434.095,2178.456 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4278.0195 2125.8987)"
            className={S.Number}
          >
            109
          </text>
          <text
            transform="matrix(1 0 0 1 4292.5776 2155.2778)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g className={unitClass(b, "110")} id="UNIT-110-G">
        <polygon
          className={S.Hot}
          points="4345.994,2248.994 4422.551,2248.994 4422.551,2228.818 4434.095,2228.818 4434.095,2178.456
				4249.386,2178.456 4249.386,2228.818 4345.994,2228.818 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4367.5259 2209.2908)"
            className={S.Number}
          >
            110
          </text>
          <text
            transform="matrix(1 0 0 1 4377.0405 2238.6699)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g className={unitClass(b, "111")} id="UNIT-111-F">
        <polygon
          className={S.Hot}
          points="4345.994,2248.994 4345.994,2228.818 4249.386,2228.818 4249.386,2318.81 4434.095,2318.81
				4434.095,2270.81 4422.551,2270.81 4422.551,2248.994 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4285.6641 2268.0757)"
            className={S.Number}
          >
            111
          </text>
          <text
            transform="matrix(1 0 0 1 4292.5776 2297.4546)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g className={unitClass(b, "112")} id="UNIT-112-G">
        <polygon
          className={S.Hot}
          points="4249.386,2372.645 4345.994,2372.645 4345.994,2392.821 4422.551,2392.821 4422.551,2370.456
				4434.095,2370.456 4434.095,2318.81 4249.386,2318.81 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4368.9692 2351.4678)"
            className={S.Number}
          >
            112
          </text>
          <text
            transform="matrix(1 0 0 1 4377.0405 2380.8467)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g className={unitClass(b, "113")} id="UNIT-113-F1">
        <polygon
          className={S.Hot}
          points="4345.994,2372.645 4249.386,2372.645 4249.386,2462.203 4434.095,2462.203 4434.095,2412.987
				4422.551,2412.987 4422.551,2392.821 4345.994,2392.821 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4282.7773 2410.2524)"
            className={S.Number}
          >
            113
          </text>
          <text
            transform="matrix(1 0 0 1 4287.9243 2439.6316)"
            className={S.Plan}
          >
            F1
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building10;
