import { createTheme, ThemeProvider } from "@mui/material";
import { grey, orange, red } from "@mui/material/colors";

type Props = {
  children: any;
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#78afc5",
    },
    info: {
      main: "#78afc5",
    },
    warning: {
      main: orange[500],
    },
    error: {
      main: red[900],
    },
    secondary: {
      main: grey[500],
    },
  },
});
const MuiTheme = ({ children }: Props) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiTheme;
