import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building16 = ({ unitClass, click }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 16" });
  return (
    <g id="BUILDING-16">
      <g
        onClick={() => click("A4")}
        className={unitClass(b, "136")}
        id="UNIT-136-A4"
      >
        <polygon
          className={S.Hot}
          points="3569.032,1748.97 3560.374,1748.97 3560.374,1789.527 3569.032,1789.527 3600.93,1789.527
				3702.551,1789.527 3702.551,1697.544 3569.032,1697.544 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3616.8552 1738.3721)"
            className={S.Number}
          >
            136
          </text>
          <text
            transform="matrix(1 0 0 1 3619.5708 1767.7511)"
            className={S.Plan}
          >
            A4
          </text>
        </g>
      </g>
      <g
        onClick={() => click("A")}
        className={unitClass(b, "137")}
        id="UNIT-137-A"
      >
        <polygon
          className={S.Hot}
          points="3569.032,1656.987 3560.374,1656.987 3560.374,1697.544 3569.032,1697.544 3702.551,1697.544
				3706.196,1697.544 3706.196,1606.405 3702.551,1606.405 3569.032,1606.405 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3617.4661 1647.3988)"
            className={S.Number}
          >
            137
          </text>
          <text
            transform="matrix(1 0 0 1 3628.2932 1676.7778)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g
        onClick={() => click("A")}
        className={unitClass(b, "138")}
        id="UNIT-138-A"
      >
        <polygon
          className={S.Hot}
          points="3569.032,1515.266 3569.032,1565.848 3560.374,1565.848 3560.374,1606.405 3569.032,1606.405
				3702.551,1606.405 3702.551,1515.266 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3617.1934 1556.4252)"
            className={S.Number}
          >
            138
          </text>
          <text
            transform="matrix(1 0 0 1 3628.2947 1585.8042)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g
        onClick={() => click("A")}
        className={unitClass(b, "139")}
        id="UNIT-139-A"
      >
        <polygon
          className={S.Hot}
          points="3702.551,1424.127 3569.032,1424.127 3569.032,1474.709 3560.374,1474.709 3560.374,1515.266
				3569.032,1515.266 3702.551,1515.266 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3616.854 1465.4523)"
            className={S.Number}
          >
            139
          </text>
          <text
            transform="matrix(1 0 0 1 3628.2932 1494.8313)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g
        onClick={() => click("A")}
        className={unitClass(b, "140")}
        id="UNIT-140-A"
      >
        <polygon
          className={S.Hot}
          points="3706.196,1424.127 3706.196,1332.987 3702.551,1332.987 3569.032,1332.987 3569.032,1383.57
				3560.374,1383.57 3560.374,1424.127 3569.032,1424.127 3702.551,1424.127 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3615.1387 1374.4791)"
            className={S.Number}
          >
            140
          </text>
          <text
            transform="matrix(1 0 0 1 3628.2932 1403.8582)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g
        onClick={() => click("A2")}
        className={unitClass(b, "141")}
        id="UNIT-141-A2"
      >
        <polygon
          className={S.Hot}
          points="3702.551,1240.481 3569.032,1240.481 3569.032,1292.43 3560.374,1292.43 3560.374,1332.987
				3569.032,1332.987 3702.551,1332.987 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3619.4028 1283.5062)"
            className={S.Number}
          >
            141
          </text>
          <text
            transform="matrix(1 0 0 1 3620.5071 1312.8853)"
            className={S.Plan}
          >
            A2
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building16;
