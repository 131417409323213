import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building22 = ({ unitClass, click }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 22" });
  return (
    <g id="BUILDING-22">
      <rect
        id="BUILDING-22-PATCH"
        x="4487.06"
        y="1105.84"
        fill="#808080"
        strokeWidth={0.5}
        stroke="#000000"
        width="29.62"
        height="52.04"
      />
      <g
        onClick={() => click("A3")}
        className={unitClass(b, "11")}
        id="UNIT-11-A3"
      >
        <polygon
          className={S.Hot}
          points="3914.905,1071.418 3873.892,1071.418 3873.892,1203.57 3873.892,1214.051 3914.905,1214.051
				3914.905,1203.57 3965.487,1203.57 3965.487,1071.418 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3911.1729 1127.6013)"
            className={S.Number}
          >
            11
          </text>
          <text
            transform="matrix(1 0 0 1 3903.2285 1156.9803)"
            className={S.Plan}
          >
            A3
          </text>
        </g>
      </g>
      <g
        onClick={() => click("F")}
        className={unitClass(b, "12")}
        id="UNIT-12-F"
      >
        <polygon
          className={S.Hot}
          points="4054.804,1115.62 4054.804,1019.924 3965.487,1019.924 3965.487,1071.418 3965.487,1203.57
				3965.487,1207.671 4017.437,1207.671 4017.437,1203.57 4017.437,1192.633 4035.131,1192.633 4035.131,1115.62 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3991.3123 1127.6014)"
            className={S.Number}
          >
            12
          </text>
          <text
            transform="matrix(1 0 0 1 3995.8999 1156.9805)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g
        onClick={() => click("G")}
        className={unitClass(b, "13")}
        id="UNIT-13-G"
      >
        <polygon
          className={S.Hot}
          points="4054.804,1019.924 4054.804,1115.62 4035.131,1115.62 4035.131,1192.633 4057.082,1192.633
				4057.082,1220.203 4065.969,1220.203 4065.969,1203.57 4107.893,1203.57 4107.209,1019.924 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4067.1367 1127.6014)"
            className={S.Number}
          >
            13
          </text>
          <text
            transform="matrix(1 0 0 1 4070.1248 1156.9805)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g
        onClick={() => click("F")}
        className={unitClass(b, "14")}
        id="UNIT-14-F"
      >
        <polygon
          className={S.Hot}
          points="4198.348,1115.62 4198.348,1019.924 4107.209,1019.924 4107.893,1203.57 4107.893,1205.734
				4159.614,1205.734 4159.614,1192.633 4179.209,1192.633 4179.209,1115.62 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4129.5371 1127.6013)"
            className={S.Number}
          >
            14
          </text>
          <text
            transform="matrix(1 0 0 1 4135.0215 1156.9805)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g
        onClick={() => click("G")}
        className={unitClass(b, "15")}
        id="UNIT-15-G"
      >
        <polygon
          className={S.Hot}
          points="4198.348,1019.924 4198.348,1115.62 4179.209,1115.62 4179.209,1192.633 4200.854,1192.633
				4200.854,1220.203 4210.196,1220.203 4210.196,1203.57 4251.209,1203.57 4251.209,1019.924 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4211.6167 1127.6013)"
            className={S.Number}
          >
            15
          </text>
          <text
            transform="matrix(1 0 0 1 4214.6699 1156.9805)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g
        onClick={() => click("F")}
        className={unitClass(b, "16")}
        id="UNIT-16-F"
      >
        <polygon
          className={S.Hot}
          points="4341.437,1115.165 4341.437,1019.924 4251.209,1019.924 4251.209,1203.57 4251.209,1205.734
				4303.842,1205.734 4303.842,1192.633 4322.297,1192.633 4322.297,1115.165 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4273.9189 1127.6013)"
            className={S.Number}
          >
            16
          </text>
          <text
            transform="matrix(1 0 0 1 4279.0654 1156.9803)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g
        onClick={() => click("G")}
        className={unitClass(b, "17")}
        id="UNIT-17-G"
      >
        <polygon
          className={S.Hot}
          points="4393.842,1019.924 4341.437,1019.924 4341.437,1115.165 4322.297,1115.165 4322.297,1192.633
				4344.626,1192.633 4344.626,1203.57 4344.626,1218.835 4353.285,1218.835 4353.285,1203.57 4393.842,1203.57 4393.842,1043.165
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4354.3257 1127.6014)"
            className={S.Number}
          >
            17
          </text>
          <text
            transform="matrix(1 0 0 1 4357.1963 1156.9805)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g
        onClick={() => click("B3")}
        className={unitClass(b, "18")}
        id="UNIT-18-B3"
      >
        <polygon
          className={S.Hot}
          points="4486.804,1111.063 4486.804,1043.165 4393.842,1043.165 4393.842,1203.57 4393.842,1204.481
				4393.842,1218.835 4403.184,1218.835 4403.184,1203.57 4435.31,1203.57 4435.31,1209.949 4491.361,1209.949 4491.361,1161.19
				4486.804,1161.19 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4426.9854 1127.6014)"
            className={S.Number}
          >
            18
          </text>
          <text
            transform="matrix(1 0 0 1 4422.9673 1156.9805)"
            className={S.Plan}
          >
            B3
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building22;
