import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building8B = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 8B" });
  return (
    <g id="BUILDING-8B">
      <g className={unitClass(b, "71")} id="UNIT-71-A2">
        <polygon
          className={S.Hot}
          points="5392.37,2121.21 5392.37,2084.68 5398.3,2084.68 5398.3,2081.4 5385.08,2081.4 5385.08,2030.48
				5252.02,2030.48 5252.02,2121.21 5385.08,2121.21 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5308.646 2074.9568)"
            className={S.Number}
          >
            71
          </text>
          <text
            transform="matrix(1 0 0 1 5303.6523 2104.3359)"
            className={S.Plan}
          >
            A2
          </text>
        </g>
      </g>
      <g className={unitClass(b, "72")} id="UNIT-72-A">
        <polygon
          className={S.Hot}
          points="5392.37,2213.47 5392.37,2176.94 5398.3,2176.94 5398.3,2173.66 5385.08,2173.66 5385.08,2121.21
				5252.02,2121.21 5252.02,2213.47 5385.08,2213.47 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5305.8247 2165.54)"
            className={S.Number}
          >
            72
          </text>
          <text
            transform="matrix(1 0 0 1 5311.4395 2194.9194)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g className={unitClass(b, "73")} id="UNIT-73-A">
        <polygon
          className={S.Hot}
          points="5394.37,2303.73 5394.37,2267.21 5400.3,2267.21 5400.3,2263.93 5385.08,2263.93 5385.08,2213.47
				5252.02,2213.47 5252.02,2303.73 5387.08,2303.73 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5305.7593 2256.1235)"
            className={S.Number}
          >
            73
          </text>
          <text
            transform="matrix(1 0 0 1 5311.4395 2285.5027)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g className={unitClass(b, "74")} id="UNIT-74-A">
        <polygon
          className={S.Hot}
          points="5396.37,2395.26 5396.37,2358.73 5402.3,2358.73 5402.3,2355.45 5387.08,2355.45 5387.08,2303.73
				5252.02,2303.73 5252.02,2395.26 5385.08,2395.26 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5300.7803 2346.7075)"
            className={S.Number}
          >
            74
          </text>
          <text
            transform="matrix(1 0 0 1 5307.293 2376.0864)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g className={unitClass(b, "75")} id="UNIT-75-A4">
        <polygon
          className={S.Hot}
          points="5252.02,2395.26 5252.02,2487.16 5393.37,2487.16 5393.37,2450.63 5399.3,2450.63 5399.3,2447.35
				5385.08,2447.35 5385.08,2395.26 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5301.5479 2437.2913)"
            className={S.Number}
          >
            75
          </text>
          <text
            transform="matrix(1 0 0 1 5298.5693 2466.6704)"
            className={S.Plan}
          >
            A4
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building8B;
