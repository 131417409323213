import { combineReducers } from "@reduxjs/toolkit";

// @ts-ignore
const createRootReducer = ({ condominiums }) => {
  return combineReducers({
    condominiums,
  });
};

export default createRootReducer;
