import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import _ from "lodash";
import { unitsAPI, statusBuildingsAPI } from "./api";
import I__Initial_State, { I__Unit } from "./I__InitialState";
import R, { I__Page } from "../constants/routes";
import { T__Buildings_Title } from "../constants/buildings";

const initialState: I__Initial_State = {
  page: R.SITEMAP,
  condoBuilding: "Building 2",
  units: [],
};

export const fetchUnits = createAsyncThunk(
  "condominiums/fetchUnits",
  async () => {
    const response = await unitsAPI();
    return response;
  }
);

export const fetchStatusBuildings = createAsyncThunk(
  "condominiums/fetchStatusBuildings",
  async () => {
    const response = await statusBuildingsAPI();
    return response;
  }
);

export const condominiumsSlice = createSlice({
  name: "condominiums",
  initialState,
  reducers: {
    updatePage: {
      reducer: (state, action: PayloadAction<{ page: I__Page }>) => {
        const { page } = action.payload;
        state.page = page;
      },
      prepare: ({ page }: { page: I__Page }) => ({
        payload: { page },
      }),
    },

    updateCondoBuilding: {
      reducer: (
        state,
        action: PayloadAction<{
          condoBuilding: T__Buildings_Title | string | undefined;
        }>
      ) => {
        const { condoBuilding } = action.payload;
        state.condoBuilding = condoBuilding;
      },
      prepare: ({
        condoBuilding,
      }: {
        condoBuilding: T__Buildings_Title | string | undefined;
      }) => ({
        payload: { condoBuilding },
      }),
    },
  },
  extraReducers: {
    [fetchUnits.fulfilled.toString()]: (state, action: any) => {
      const data = action.payload;
      // console.log('fetch units data =', data);
      const ordered = _.orderBy(
        data,
        (u: I__Unit) => {
          return +u.unit;
        },
        "desc"
      );
      // console.log(ordered);
      state.units = ordered;
    },
  },
});

export const { updatePage, updateCondoBuilding } = condominiumsSlice.actions;

// @ts-ignore
export const selectPage = (state: RootState) => state.condominiums.page;

export const selectCondoBuilding = (state: RootState) =>
  // @ts-ignore
  state.condominiums.condoBuilding;

// @ts-ignore
export const selectUnits = (state: RootState) => state.condominiums.units;

// @ts-ignore
export default condominiumsSlice.reducer;
