import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building17 = ({ unitClass, click }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 17" });
  return (
    <g id="BUILDING-17">
      <rect
        id="Building-17-Patch"
        x="4011.248"
        y="1757.833"
        fill="#808080"
        strokeWidth={0.5}
        stroke="#000000"
        width="49.077"
        height="31.122"
      />
      <g
        onClick={() => click("D1")}
        className={unitClass(b, "112")}
        id="UNIT-112-D1"
      >
        <polygon
          className={S.Hot}
          points="4138.401,1373.79 4138.401,1321.001 4033.21,1321.001 4033.21,1394.583 4167.511,1394.583
				4167.511,1373.79 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4069.5764 1354.9586)"
            className={S.Number}
          >
            112
          </text>
          <text
            transform="matrix(1 0 0 1 4073.0205 1384.3376)"
            className={S.Plan}
          >
            D1
          </text>
        </g>
      </g>
      <g
        onClick={() => click("D")}
        className={unitClass(b, "113")}
        id="UNIT-113-D"
      >
        <polygon
          className={S.Hot}
          points="4033.21,1467.935 4138.401,1467.935 4138.401,1415.375 4167.511,1415.375 4167.511,1394.583
				4033.21,1394.583 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4069.5098 1427.4331)"
            className={S.Number}
          >
            113
          </text>
          <text
            transform="matrix(1 0 0 1 4077.6726 1456.8121)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g
        onClick={() => click("D")}
        className={unitClass(b, "114")}
        id="UNIT-114-D"
      >
        <polygon
          className={S.Hot}
          points="4033.21,1540.478 4167.511,1540.478 4167.511,1519.164 4138.401,1519.164 4138.401,1467.935
				4033.21,1467.935 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4068.6777 1499.6606)"
            className={S.Number}
          >
            114
          </text>
          <text
            transform="matrix(1 0 0 1 4077.6726 1529.0397)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g
        onClick={() => click("D")}
        className={unitClass(b, "115")}
        id="UNIT-115-D"
      >
        <polygon
          className={S.Hot}
          points="4033.21,1612.559 4138.401,1612.559 4138.401,1560.749 4167.511,1560.749 4167.511,1540.478
				4033.21,1540.478 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4069.4465 1572.012)"
            className={S.Number}
          >
            115
          </text>
          <text
            transform="matrix(1 0 0 1 4077.6738 1601.391)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g
        onClick={() => click("D")}
        className={unitClass(b, "116")}
        id="UNIT-116-D"
      >
        <polygon
          className={S.Hot}
          points="4033.21,1684.64 4167.511,1684.64 4167.511,1664.771 4138.401,1664.771 4138.401,1612.559
				4033.21,1612.559 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4069.0164 1644.3636)"
            className={S.Number}
          >
            116
          </text>
          <text
            transform="matrix(1 0 0 1 4077.6733 1673.7427)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g
        onClick={() => click("D1")}
        className={unitClass(b, "117")}
        id="UNIT-117-D1"
      >
        <polygon
          className={S.Hot}
          points="4033.21,1758.912 4138.401,1758.912 4138.401,1706.357 4167.51,1706.357 4167.51,1684.64
				4033.21,1684.64 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4069.6282 1716.7145)"
            className={S.Number}
          >
            117
          </text>
          <text
            transform="matrix(1 0 0 1 4073.0205 1746.0935)"
            className={S.Plan}
          >
            D1
          </text>
        </g>
      </g>
      <g
        onClick={() => click("D1")}
        className={unitClass(b, "118")}
        id="UNIT-118-D1"
      >
        <polygon
          className={S.Hot}
          points="4033.21,1758.912 4033.21,1684.64 3901.827,1684.64 3901.827,1706.357 3931.861,1706.357
				3931.861,1758.912 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3963.7961 1716.7145)"
            className={S.Number}
          >
            118
          </text>
          <text
            transform="matrix(1 0 0 1 3967.4609 1746.0935)"
            className={S.Plan}
          >
            D1
          </text>
        </g>
      </g>
      <g
        onClick={() => click("D")}
        className={unitClass(b, "119")}
        id="UNIT-119-D"
      >
        <polygon
          className={S.Hot}
          points="4033.21,1612.559 3931.861,1612.559 3931.861,1664.771 3901.827,1664.771 3901.827,1684.64
				4033.21,1684.64 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3963.4568 1644.3636)"
            className={S.Number}
          >
            119
          </text>
          <text
            transform="matrix(1 0 0 1 3972.1138 1673.7427)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g
        onClick={() => click("D")}
        className={unitClass(b, "120")}
        id="UNIT-120-D"
      >
        <polygon
          className={S.Hot}
          points="4033.21,1540.478 3901.827,1540.478 3901.827,1560.749 3931.861,1560.749 3931.861,1612.559
				4033.21,1612.559 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3959.7522 1572.012)"
            className={S.Number}
          >
            120
          </text>
          <text
            transform="matrix(1 0 0 1 3972.1143 1601.391)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g
        onClick={() => click("D")}
        className={unitClass(b, "121")}
        id="UNIT-121-D"
      >
        <polygon
          className={S.Hot}
          points="4033.21,1467.935 3931.861,1467.935 3931.861,1519.164 3901.827,1519.164 3901.827,1540.478
				4033.21,1540.478 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3964.0156 1499.6606)"
            className={S.Number}
          >
            121
          </text>
          <text
            transform="matrix(1 0 0 1 3972.113 1529.0397)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g
        onClick={() => click("D")}
        className={unitClass(b, "122")}
        id="UNIT-122-D"
      >
        <polygon
          className={S.Hot}
          points="4033.21,1394.583 3901.827,1394.583 3901.827,1415.375 3931.861,1415.375 3931.861,1467.935
				4033.21,1467.935 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3961.1943 1427.4331)"
            className={S.Number}
          >
            122
          </text>
          <text
            transform="matrix(1 0 0 1 3972.113 1456.8121)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g
        onClick={() => click("D1")}
        className={unitClass(b, "123")}
        id="UNIT-123-D1"
      >
        <polygon
          className={S.Hot}
          points="4033.21,1321.001 3931.861,1321.001 3931.861,1373.79 3901.827,1373.79 3901.827,1394.583
				4033.21,1394.583 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3961.1311 1354.9586)"
            className={S.Number}
          >
            123
          </text>
          <text
            transform="matrix(1 0 0 1 3967.4609 1384.3376)"
            className={S.Plan}
          >
            D1
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building17;
