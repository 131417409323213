import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building1 = ({ unitClass, click }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 1" });
  return (
    <g id="BUILDING-1">
      <g
        onClick={() => click("F2")}
        className={unitClass(b, "1")}
        id="UNIT-1-F2"
      >
        <polygon
          className={S.Hot}
          points="3673.842,806.203 3673.842,724.405 3654.703,724.405 3654.703,713.924 3602.867,713.924
				3602.867,767.24 3588.854,767.24 3588.854,778.633 3602.867,778.633 3602.867,900.076 3693.209,900.076 3693.209,806.203 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3635.7048 801.53)"
            className={S.Number}
          >
            1
          </text>
          <text
            transform="matrix(1 0 0 1 3624.5378 830.9091)"
            className={S.Plan}
          >
            F2
          </text>
        </g>
      </g>
      <g onClick={() => click("G")} className={unitClass(b, "2")} id="UNIT-2-G">
        <polygon
          className={S.Hot}
          points="3704.601,715.519 3704.601,699.342 3694.576,699.342 3694.576,724.405 3673.842,724.405
				3673.842,806.203 3693.209,806.203 3693.209,900.076 3745.956,900.076 3745.956,851.544 3745.956,715.519 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3701.9304 762.3581)"
            className={S.Number}
          >
            2
          </text>
          <text
            transform="matrix(1 0 0 1 3699.7056 791.7372)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g onClick={() => click("F")} className={unitClass(b, "3")} id="UNIT-3-F">
        <polygon
          className={S.Hot}
          points="3816.93,757.899 3816.93,675.873 3797.108,675.873 3797.108,665.62 3745.956,665.62
				3745.956,715.519 3745.956,851.544 3836.753,851.544 3836.753,757.899 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3780.6328 753.8216)"
            className={S.Number}
          >
            3
          </text>
          <text
            transform="matrix(1 0 0 1 3780.1375 783.2006)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g onClick={() => click("G")} className={unitClass(b, "4")} id="UNIT-4-G">
        <polygon
          className={S.Hot}
          points="3847.69,666.304 3847.69,650.81 3838.348,650.81 3838.348,675.873 3816.93,675.873 3816.93,757.899
				3836.753,757.899 3836.753,851.544 3889.5,851.544 3889.5,802.785 3889.5,666.304 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3846.1758 713.6633)"
            className={S.Number}
          >
            4
          </text>
          <text
            transform="matrix(1 0 0 1 3844.8486 743.0423)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g onClick={() => click("F")} className={unitClass(b, "5")} id="UNIT-5-F">
        <polygon
          className={S.Hot}
          points="3959.791,709.139 3959.791,627.342 3939.74,627.342 3939.74,616.804 3889.5,616.804 3889.5,666.304
				3889.5,802.785 3979.614,802.785 3979.614,709.139 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3917.5842 708.1721)"
            className={S.Number}
          >
            5
          </text>
          <text
            transform="matrix(1 0 0 1 3917.1536 737.5512)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g
        onClick={() => click("G1")}
        className={unitClass(b, "6")}
        id="UNIT-6-G1"
      >
        <polygon
          className={S.Hot}
          points="4033.158,678.835 4033.158,616.804 3990.323,616.804 3990.323,602.279 3980.525,602.279
				3980.525,627.342 3959.791,627.342 3959.791,709.139 3979.614,709.139 3979.614,802.785 4033.158,802.785 4033.158,729.19
				4062.779,729.19 4062.779,678.835 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3998.5701 698.9736)"
            className={S.Number}
          >
            6
          </text>
          <text
            transform="matrix(1 0 0 1 3992.2515 728.3526)"
            className={S.Plan}
          >
            G1
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building1;
