import S from "../SVG.module.sass";

const BuildingLabels = () => {
  return (
    <g id="BUILDING_LABELS" className={S.BuildingLabels}>
      <g>
        <path
          d="M4668.813,1136.18v21.144h2.981v0.502h-9.248v-0.502h2.982v-21.144h-4.657c-1.575,0-2.011,0.67-2.279,3.116l-0.302,2.647
			h-0.502c0,0,0.201-3.016,0.201-4.155v-2.111h18.362v2.111c0,1.139,0.134,4.155,0.134,4.155h-0.502l-0.235-2.647
			c-0.201-2.446-0.703-3.116-2.278-3.116H4668.813z"
        />
        <path
          d="M4679.398,1157.324h2.982v-21.144h-2.982v-0.502h9.248v0.502h-2.982v10.253h11.795v-10.253h-2.981v-0.502h9.248v0.502
			h-2.982v21.144h2.982v0.502h-9.248v-0.502h2.981v-10.388h-11.795v10.388h2.982v0.502h-9.248V1157.324z"
        />
        <path
          d="M4706.903,1157.324h2.982v-21.144h-2.982v-0.502h16.854c0,0-0.703,2.647-0.972,6.266h-0.503v-3.619
			c0-2.078-0.335-2.145-1.508-2.145h-7.606v10.455h5.361v-2.949h0.503v6.702h-0.503v-3.25h-5.361v10.187h7.405
			c1.877,0,2.045-1.776,2.748-6.132h0.536c0,0-0.503,3.049-0.503,6.635h-16.452V1157.324z"
        />
        <path
          d="M4626.098,1177.725c1.005-0.033,1.307-0.771,1.642-1.843h0.436c0,0-0.637,3.25-0.637,4.691v1.977h-0.502v-1.039
			c0-3.217-2.614-5.361-6.199-5.361c-5.663,0-6.803,5.495-6.803,10.856c0,5.596,1.273,10.756,6.534,10.756
			c1.608,0,2.982-0.402,4.021-1.072c1.843-1.206,2.781-3.317,2.781-5.261h0.536c0,2.479-1.676,6.835-7.339,6.835
			c-6.064,0-10.119-4.858-10.119-11.426c0-6.635,4.256-11.191,10.388-11.191C4623.35,1175.647,4625.059,1176.585,4626.098,1177.725z
			"
        />
        <path
          d="M4643.552,1197.528h2.346l-3.149-7.573h-7.707l-2.077,5.596c-0.436,1.206,0.268,1.977,1.81,1.977h1.005v0.502h-6.199
			v-0.502h0.67c1.039,0,1.643-0.503,2.179-1.977l7.405-19.804h0.502l9.048,21.781h1.642v0.502h-7.473V1197.528z M4635.242,1189.453
			h7.271l-3.854-9.215L4635.242,1189.453z"
        />
        <path
          d="M4671.561,1195.25h0.503c-0.034,2.044-1.039,2.949-2.781,2.949c-3.786,0-4.188-3.719-4.323-5.596
			c-0.134-1.943-0.469-5.361-3.316-5.361h-2.982v10.287h2.948v0.502h-9.215v-0.502h2.982v-21.144h-2.982v-0.502h8.947
			c5.763,0,7.941,2.312,7.941,5.629c0,2.647-1.642,4.624-5.965,5.428v0.067c3.016,0.704,5.228,2.513,5.361,6.099
			c0.101,2.714,0.134,4.356,1.441,4.356C4670.991,1197.461,4671.561,1196.489,4671.561,1195.25z M4661.575,1186.738
			c3.149,0,4.155-1.675,4.155-5.026c0-3.452-1.106-5.328-4.323-5.328h-2.747v10.354H4661.575z"
        />
        <path
          d="M4674.405,1197.528h2.982v-21.144h-2.982v-0.502h9.65c7.841,0,11.158,4.222,11.158,10.723
			c0,6.333-3.083,11.426-11.158,11.426h-9.65V1197.528z M4680.671,1176.384v21.144h3.452c5.025,0,7.506-3.652,7.506-10.555
			c0-7.205-2.48-10.589-7.506-10.589H4680.671z"
        />
        <path d="M4698.662,1197.528h2.982v-21.144h-2.982v-0.502h9.248v0.502h-2.982v21.144h2.982v0.502h-9.248V1197.528z" />
        <path
          d="M4714.341,1177.926c-0.703-0.938-1.105-1.542-2.747-1.542h-0.938v-0.502h6.199l12.599,16.788v-14.274
			c0-1.273-0.536-2.011-2.011-2.011h-1.005v-0.502h6.567v0.502h-1.038c-1.475,0-1.978,0.737-1.978,2.011v19.803h-0.503
			l-14.609-19.535v16.854c0,1.273,0.503,2.011,1.978,2.011h1.038v0.502h-6.567v-0.502h1.005c1.475,0,2.011-0.737,2.011-2.011
			V1177.926z"
        />
        <path
          d="M4748.013,1197.528h2.346l-3.149-7.573h-7.707l-2.077,5.596c-0.436,1.206,0.268,1.977,1.81,1.977h1.005v0.502h-6.199
			v-0.502h0.67c1.039,0,1.643-0.503,2.179-1.977l7.405-19.804h0.502l9.048,21.781h1.642v0.502h-7.473V1197.528z M4739.703,1189.453
			h7.271l-3.854-9.215L4739.703,1189.453z"
        />
        <path
          d="M4763.188,1197.528h7.171c1.877,0,2.044-1.776,2.748-6.132h0.536c0,0-0.503,2.949-0.503,6.635h-16.218v-0.502h2.982
			v-21.144h-2.982v-0.502h9.248v0.502h-2.982V1197.528z"
        />
        <path
          d="M4665.362,1217.929c1.005-0.033,1.307-0.771,1.642-1.843h0.436c0,0-0.637,3.25-0.637,4.691v1.977h-0.502v-1.039
			c0-3.217-2.614-5.361-6.199-5.361c-5.663,0-6.803,5.495-6.803,10.856c0,5.596,1.273,10.756,6.534,10.756
			c1.608,0,2.982-0.402,4.021-1.072c1.843-1.206,2.781-3.317,2.781-5.261h0.536c0,2.479-1.676,6.835-7.339,6.835
			c-6.064,0-10.119-4.858-10.119-11.426c0-6.635,4.256-11.191,10.388-11.191C4662.614,1215.852,4664.323,1216.79,4665.362,1217.929z
			"
        />
        <path
          d="M4676.919,1237.732h7.171c1.877,0,2.044-1.776,2.748-6.132h0.536c0,0-0.503,2.949-0.503,6.635h-16.218v-0.502h2.982
			v-21.144h-2.982v-0.502h9.248v0.502h-2.982V1237.732z"
        />
        <path
          d="M4692.195,1216.588h-2.982v-0.502h9.282v0.502h-2.982v12.331c0,6.434,1.475,9.047,6.065,9.047
			c4.791,0,7.069-3.016,7.069-8.276v-11.091c0-1.273-0.536-2.011-2.01-2.011h-1.006v-0.502h6.534v0.502h-1.038
			c-1.475,0-1.978,0.737-1.978,2.011v11.158c0,4.859-2.077,8.712-7.572,8.712c-7.271,0-9.383-3.518-9.383-9.55V1216.588z"
        />
        <path
          d="M4714.877,1237.732h2.982v-21.144h-2.982v-0.502h8.98c5.462,0,7.874,2.446,7.874,5.328c0,2.279-1.675,4.356-5.395,5.294
			v0.067c4.322,0.804,6.434,2.681,6.434,5.496c0,3.217-2.514,5.964-8.545,5.964h-9.349V1237.732z M4723.891,1226.641
			c2.513,0,4.39-0.972,4.39-4.792c0-3.887-1.843-5.261-4.523-5.261h-2.614v10.053H4723.891z M4724.192,1237.732
			c3.384,0,5.127-2.011,5.127-5.629c0-3.585-1.844-4.959-5.194-4.959h-2.982v10.589H4724.192z"
        />
      </g>
      <g>
        <path d="M1874.906,769.257h16.854v2.446h-14.208v8.377h12.7v2.413h-12.7v10.22h-2.647V769.257z" />
        <path
          d="M1896.516,782.895v-13.638h2.647v13.47c0,5.06,2.714,7.908,7.171,7.908c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
			v13.437c0,6.836-3.92,10.388-9.784,10.388C1900.47,793.082,1896.516,789.53,1896.516,782.895z"
        />
        <path d="M1928.512,771.703h-7.874v-2.446h18.429v2.446h-7.875v21.01h-2.681V771.703z" />
        <path
          d="M1943.654,782.895v-13.638h2.647v13.47c0,5.06,2.714,7.908,7.171,7.908c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
			v13.437c0,6.836-3.92,10.388-9.784,10.388C1947.608,793.082,1943.654,789.53,1943.654,782.895z"
        />
        <path
          d="M1969.452,769.257h10.086c2.882,0,5.194,0.871,6.668,2.345c1.14,1.139,1.81,2.781,1.81,4.625v0.067
			c0,3.887-2.681,6.166-6.367,6.835l7.205,9.583h-3.25l-6.802-9.114h-6.702v9.114h-2.647V769.257z M1979.303,781.22
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.881-2.211-4.624-5.998-4.624h-7.238v9.517H1979.303z"
        />
        <path d="M1993.674,769.257h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V769.257z" />
        <path
          d="M1865.643,809.461h10.086c2.882,0,5.194,0.871,6.668,2.345c1.14,1.139,1.81,2.781,1.81,4.625v0.067
			c0,3.887-2.681,6.166-6.367,6.835l7.205,9.583h-3.25l-6.802-9.114h-6.702v9.114h-2.647V809.461z M1875.494,821.424
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.881-2.211-4.624-5.998-4.624h-7.238v9.517H1875.494z"
        />
        <path d="M1889.865,809.461h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V809.461z" />
        <path d="M1912.312,809.461h2.647v21.009h13.203v2.446h-15.85V809.461z" />
        <path d="M1933.051,809.461h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V809.461z" />
        <path
          d="M1964.177,809.293h2.479l10.689,23.624h-2.848l-2.748-6.199h-12.767l-2.781,6.199h-2.714L1964.177,809.293z
			 M1970.711,824.305l-5.328-11.929l-5.361,11.929H1970.711z"
        />
        <path
          d="M1979.955,829.499l1.642-1.943c2.446,2.211,4.792,3.317,8.042,3.317c3.15,0,5.227-1.675,5.227-3.988v-0.067
			c0-2.178-1.173-3.417-6.099-4.457c-5.395-1.173-7.874-2.915-7.874-6.769v-0.067c0-3.686,3.25-6.4,7.707-6.4
			c3.418,0,5.864,0.972,8.243,2.882l-1.542,2.044c-2.178-1.776-4.356-2.546-6.769-2.546c-3.049,0-4.993,1.675-4.993,3.786v0.067
			c0,2.211,1.207,3.451,6.367,4.557c5.227,1.14,7.64,3.049,7.64,6.635v0.067c0,4.021-3.351,6.634-8.008,6.634
			C1985.819,833.252,1982.77,832.012,1979.955,829.499z"
        />
        <path d="M2002.938,809.461h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V809.461z" />
      </g>
      <g>
        <path d="M941.973,819.143h16.854v2.446H944.62v8.377h12.7v2.413h-12.7v10.22h-2.647V819.143z" />
        <path
          d="M963.582,832.781v-13.638h2.647v13.47c0,5.06,2.714,7.908,7.171,7.908c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647v13.437
			c0,6.836-3.92,10.388-9.784,10.388C967.536,842.967,963.582,839.416,963.582,832.781z"
        />
        <path d="M995.578,821.589h-7.874v-2.446h18.429v2.446h-7.875v21.01h-2.681V821.589z" />
        <path
          d="M1010.721,832.781v-13.638h2.647v13.47c0,5.06,2.714,7.908,7.171,7.908c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
			v13.437c0,6.836-3.92,10.388-9.784,10.388C1014.675,842.967,1010.721,839.416,1010.721,832.781z"
        />
        <path
          d="M1036.518,819.143h10.086c2.882,0,5.194,0.871,6.668,2.345c1.14,1.139,1.81,2.781,1.81,4.625v0.067
			c0,3.887-2.681,6.166-6.367,6.835l7.205,9.583h-3.25l-6.802-9.114h-6.702v9.114h-2.647V819.143z M1046.37,831.105
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.881-2.211-4.624-5.998-4.624h-7.238v9.517H1046.37z"
        />
        <path d="M1060.741,819.143h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V819.143z" />
        <path
          d="M932.709,859.347h10.086c2.882,0,5.194,0.871,6.668,2.345c1.14,1.139,1.81,2.781,1.81,4.625v0.067
			c0,3.887-2.681,6.166-6.367,6.835l7.205,9.583h-3.25l-6.802-9.114h-6.702v9.114h-2.647V859.347z M942.561,871.31
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.881-2.211-4.624-5.998-4.624h-7.238v9.517H942.561z"
        />
        <path d="M956.932,859.347h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V859.347z" />
        <path d="M979.379,859.347h2.647v21.009h13.203v2.446h-15.85V859.347z" />
        <path d="M1000.117,859.347h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V859.347z" />
        <path
          d="M1031.243,859.179h2.479l10.689,23.624h-2.848l-2.748-6.199h-12.767l-2.781,6.199h-2.714L1031.243,859.179z
			 M1037.777,874.191l-5.328-11.929l-5.361,11.929H1037.777z"
        />
        <path
          d="M1047.021,879.385l1.642-1.943c2.446,2.211,4.792,3.317,8.042,3.317c3.15,0,5.227-1.675,5.227-3.988v-0.067
			c0-2.178-1.173-3.417-6.099-4.457c-5.395-1.173-7.874-2.915-7.874-6.769v-0.067c0-3.686,3.25-6.4,7.707-6.4
			c3.418,0,5.864,0.972,8.243,2.882l-1.542,2.044c-2.178-1.776-4.356-2.546-6.769-2.546c-3.049,0-4.993,1.675-4.993,3.786v0.067
			c0,2.211,1.207,3.451,6.367,4.557c5.227,1.14,7.64,3.049,7.64,6.635v0.067c0,4.021-3.351,6.634-8.008,6.634
			C1052.886,883.138,1049.836,881.898,1047.021,879.385z"
        />
        <path d="M1070.005,859.347h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V859.347z" />
      </g>
      <g>
        <path d="M1649.39,1469.549h16.854v2.445h-14.208v8.377h12.7v2.413h-12.7v10.22h-2.647V1469.549z" />
        <path
          d="M1670.999,1483.187v-13.638h2.647v13.47c0,5.06,2.714,7.908,7.171,7.908c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
			v13.437c0,6.836-3.92,10.388-9.784,10.388C1674.953,1493.373,1670.999,1489.821,1670.999,1483.187z"
        />
        <path d="M1702.995,1471.994h-7.874v-2.445h18.429v2.445h-7.875v21.01h-2.681V1471.994z" />
        <path
          d="M1718.138,1483.187v-13.638h2.647v13.47c0,5.06,2.714,7.908,7.171,7.908c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
			v13.437c0,6.836-3.92,10.388-9.784,10.388C1722.092,1493.373,1718.138,1489.821,1718.138,1483.187z"
        />
        <path
          d="M1743.935,1469.549h10.086c2.882,0,5.194,0.871,6.668,2.345c1.14,1.14,1.81,2.781,1.81,4.625v0.066
			c0,3.887-2.681,6.166-6.367,6.836l7.205,9.583h-3.25l-6.802-9.114h-6.702v9.114h-2.647V1469.549z M1753.787,1481.511
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.881-2.211-4.624-5.998-4.624h-7.238v9.517H1753.787z"
        />
        <path d="M1768.158,1469.549h16.955v2.412h-14.308v8.009h12.8v2.412h-12.8v8.21h14.476v2.412h-17.123V1469.549z" />
        <path
          d="M1640.126,1509.753h10.086c2.882,0,5.194,0.871,6.668,2.345c1.14,1.14,1.81,2.781,1.81,4.625v0.066
			c0,3.887-2.681,6.166-6.367,6.836l7.205,9.583h-3.25l-6.802-9.114h-6.702v9.114h-2.647V1509.753z M1649.978,1521.715
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.881-2.211-4.624-5.998-4.624h-7.238v9.517H1649.978z"
        />
        <path d="M1664.349,1509.753h16.955v2.412h-14.308v8.009h12.8v2.412h-12.8v8.21h14.476v2.412h-17.123V1509.753z" />
        <path d="M1686.796,1509.753h2.647v21.009h13.203v2.446h-15.85V1509.753z" />
        <path d="M1707.534,1509.753h16.955v2.412h-14.308v8.009h12.8v2.412h-12.8v8.21h14.476v2.412h-17.123V1509.753z" />
        <path
          d="M1738.66,1509.585h2.479l10.689,23.623h-2.848l-2.748-6.199h-12.767l-2.781,6.199h-2.714L1738.66,1509.585z
			 M1745.194,1524.597l-5.328-11.929l-5.361,11.929H1745.194z"
        />
        <path
          d="M1754.438,1529.79l1.642-1.943c2.446,2.212,4.792,3.317,8.042,3.317c3.15,0,5.227-1.675,5.227-3.987v-0.067
			c0-2.178-1.173-3.417-6.099-4.456c-5.395-1.173-7.874-2.915-7.874-6.769v-0.067c0-3.686,3.25-6.4,7.707-6.4
			c3.418,0,5.864,0.972,8.243,2.882l-1.542,2.044c-2.178-1.775-4.356-2.546-6.769-2.546c-3.049,0-4.993,1.675-4.993,3.786v0.067
			c0,2.211,1.207,3.451,6.367,4.557c5.227,1.14,7.64,3.049,7.64,6.635v0.067c0,4.021-3.351,6.634-8.008,6.634
			C1760.303,1533.543,1757.253,1532.304,1754.438,1529.79z"
        />
        <path d="M1777.422,1509.753h16.955v2.412h-14.308v8.009h12.8v2.412h-12.8v8.21h14.476v2.412h-17.123V1509.753z" />
      </g>
      <g>
        <path d="M760.75,1512.038h16.854v2.446h-14.208v8.377h12.7v2.413h-12.7v10.22h-2.647V1512.038z" />
        <path
          d="M782.359,1525.675v-13.638h2.647v13.47c0,5.06,2.714,7.908,7.171,7.908c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
			v13.437c0,6.836-3.92,10.388-9.784,10.388C786.313,1535.862,782.359,1532.31,782.359,1525.675z"
        />
        <path d="M814.355,1514.483h-7.874v-2.446h18.429v2.446h-7.875v21.01h-2.681V1514.483z" />
        <path
          d="M829.498,1525.675v-13.638h2.647v13.47c0,5.06,2.714,7.908,7.171,7.908c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
			v13.437c0,6.836-3.92,10.388-9.784,10.388C833.452,1535.862,829.498,1532.31,829.498,1525.675z"
        />
        <path
          d="M855.295,1512.038h10.086c2.882,0,5.194,0.871,6.668,2.345c1.14,1.139,1.81,2.781,1.81,4.625v0.067
			c0,3.887-2.681,6.166-6.367,6.835l7.205,9.583h-3.25l-6.802-9.114h-6.702v9.114h-2.647V1512.038z M865.147,1524
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.881-2.211-4.624-5.998-4.624h-7.238V1524H865.147z"
        />
        <path d="M879.518,1512.038h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V1512.038z" />
        <path
          d="M751.486,1552.242h10.086c2.882,0,5.194,0.871,6.668,2.345c1.14,1.139,1.81,2.781,1.81,4.625v0.067
			c0,3.887-2.681,6.166-6.367,6.835l7.205,9.583h-3.25l-6.802-9.114h-6.702v9.114h-2.647V1552.242z M761.338,1564.204
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.881-2.211-4.624-5.998-4.624h-7.238v9.517H761.338z"
        />
        <path d="M775.709,1552.242h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V1552.242z" />
        <path d="M798.156,1552.242h2.647v21.009h13.203v2.446h-15.85V1552.242z" />
        <path d="M818.895,1552.242h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V1552.242z" />
        <path
          d="M850.021,1552.074h2.479l10.689,23.624h-2.848l-2.748-6.199h-12.767l-2.781,6.199h-2.714L850.021,1552.074z
			 M856.555,1567.085l-5.328-11.929l-5.361,11.929H856.555z"
        />
        <path
          d="M865.799,1572.279l1.642-1.943c2.446,2.211,4.792,3.317,8.042,3.317c3.15,0,5.227-1.675,5.227-3.988v-0.067
			c0-2.178-1.173-3.417-6.099-4.457c-5.395-1.173-7.874-2.915-7.874-6.769v-0.067c0-3.686,3.25-6.4,7.707-6.4
			c3.418,0,5.864,0.972,8.243,2.882l-1.542,2.044c-2.178-1.776-4.356-2.546-6.769-2.546c-3.049,0-4.993,1.675-4.993,3.786v0.067
			c0,2.211,1.207,3.451,6.367,4.557c5.227,1.14,7.64,3.049,7.64,6.635v0.067c0,4.021-3.351,6.634-8.008,6.634
			C871.663,1576.032,868.614,1574.792,865.799,1572.279z"
        />
        <path d="M888.782,1552.242h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V1552.242z" />
      </g>
      <g>
        <path d="M936.45,2276.004h16.854v2.446h-14.208v8.377h12.7v2.412h-12.7v10.221h-2.647V2276.004z" />
        <path
          d="M958.06,2289.642v-13.638h2.647v13.471c0,5.06,2.714,7.907,7.171,7.907c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
			v13.437c0,6.836-3.92,10.388-9.784,10.388C962.014,2299.828,958.06,2296.276,958.06,2289.642z"
        />
        <path d="M990.056,2278.45h-7.874v-2.446h18.429v2.446h-7.875v21.01h-2.681V2278.45z" />
        <path
          d="M1005.198,2289.642v-13.638h2.647v13.471c0,5.06,2.714,7.907,7.171,7.907c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
			v13.437c0,6.836-3.92,10.388-9.784,10.388C1009.152,2299.828,1005.198,2296.276,1005.198,2289.642z"
        />
        <path
          d="M1030.996,2276.004h10.086c2.882,0,5.194,0.871,6.668,2.346c1.14,1.139,1.81,2.781,1.81,4.624v0.067
			c0,3.887-2.681,6.165-6.367,6.835l7.205,9.584h-3.25l-6.802-9.114h-6.702v9.114h-2.647V2276.004z M1040.847,2287.967
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.882-2.211-4.624-5.998-4.624h-7.238v9.517H1040.847z"
        />
        <path d="M1055.218,2276.004h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V2276.004z" />
        <path
          d="M927.187,2316.208h10.086c2.882,0,5.194,0.871,6.668,2.346c1.14,1.139,1.81,2.781,1.81,4.624v0.067
			c0,3.887-2.681,6.165-6.367,6.835l7.205,9.584h-3.25l-6.802-9.114h-6.702v9.114h-2.647V2316.208z M937.038,2328.171
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.882-2.211-4.624-5.998-4.624h-7.238v9.517H937.038z"
        />
        <path d="M951.409,2316.208h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V2316.208z" />
        <path d="M973.856,2316.208h2.647v21.01h13.203v2.446h-15.85V2316.208z" />
        <path d="M994.595,2316.208h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V2316.208z" />
        <path
          d="M1025.721,2316.04h2.479l10.689,23.624h-2.848l-2.748-6.199h-12.767l-2.781,6.199h-2.714L1025.721,2316.04z
			 M1032.255,2331.052l-5.328-11.929l-5.361,11.929H1032.255z"
        />
        <path
          d="M1041.499,2336.246l1.642-1.943c2.446,2.211,4.792,3.317,8.042,3.317c3.15,0,5.227-1.676,5.227-3.988v-0.066
			c0-2.178-1.173-3.418-6.099-4.457c-5.395-1.173-7.874-2.915-7.874-6.769v-0.066c0-3.687,3.25-6.4,7.707-6.4
			c3.418,0,5.864,0.972,8.243,2.882l-1.542,2.044c-2.178-1.776-4.356-2.547-6.769-2.547c-3.049,0-4.993,1.676-4.993,3.786v0.067
			c0,2.212,1.207,3.451,6.367,4.557c5.227,1.14,7.64,3.05,7.64,6.635v0.067c0,4.021-3.351,6.635-8.008,6.635
			C1047.363,2339.999,1044.314,2338.759,1041.499,2336.246z"
        />
        <path d="M1064.482,2316.208h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V2316.208z" />
      </g>
      <g>
        <path d="M1877.3,2276.004h16.854v2.446h-14.208v8.377h12.7v2.412h-12.7v10.221h-2.647V2276.004z" />
        <path
          d="M1898.909,2289.642v-13.638h2.647v13.471c0,5.06,2.714,7.907,7.171,7.907c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
			v13.437c0,6.836-3.92,10.388-9.784,10.388C1902.863,2299.828,1898.909,2296.276,1898.909,2289.642z"
        />
        <path d="M1930.905,2278.45h-7.874v-2.446h18.429v2.446h-7.875v21.01h-2.681V2278.45z" />
        <path
          d="M1946.048,2289.642v-13.638h2.647v13.471c0,5.06,2.714,7.907,7.171,7.907c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
			v13.437c0,6.836-3.92,10.388-9.784,10.388C1950.002,2299.828,1946.048,2296.276,1946.048,2289.642z"
        />
        <path
          d="M1971.845,2276.004h10.086c2.882,0,5.194,0.871,6.668,2.346c1.14,1.139,1.81,2.781,1.81,4.624v0.067
			c0,3.887-2.681,6.165-6.367,6.835l7.205,9.584h-3.25l-6.802-9.114h-6.702v9.114h-2.647V2276.004z M1981.697,2287.967
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.882-2.211-4.624-5.998-4.624h-7.238v9.517H1981.697z"
        />
        <path d="M1996.068,2276.004h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V2276.004z" />
        <path
          d="M1868.036,2316.208h10.086c2.882,0,5.194,0.871,6.668,2.346c1.14,1.139,1.81,2.781,1.81,4.624v0.067
			c0,3.887-2.681,6.165-6.367,6.835l7.205,9.584h-3.25l-6.802-9.114h-6.702v9.114h-2.647V2316.208z M1877.888,2328.171
			c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.882-2.211-4.624-5.998-4.624h-7.238v9.517H1877.888z"
        />
        <path d="M1892.259,2316.208h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V2316.208z" />
        <path d="M1914.706,2316.208h2.647v21.01h13.203v2.446h-15.85V2316.208z" />
        <path d="M1935.444,2316.208h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V2316.208z" />
        <path
          d="M1966.57,2316.04h2.479l10.689,23.624h-2.848l-2.748-6.199h-12.767l-2.781,6.199h-2.714L1966.57,2316.04z
			 M1973.104,2331.052l-5.328-11.929l-5.361,11.929H1973.104z"
        />
        <path
          d="M1982.349,2336.246l1.642-1.943c2.446,2.211,4.792,3.317,8.042,3.317c3.15,0,5.227-1.676,5.227-3.988v-0.066
			c0-2.178-1.173-3.418-6.099-4.457c-5.395-1.173-7.874-2.915-7.874-6.769v-0.066c0-3.687,3.25-6.4,7.707-6.4
			c3.418,0,5.864,0.972,8.243,2.882l-1.542,2.044c-2.178-1.776-4.356-2.547-6.769-2.547c-3.049,0-4.993,1.676-4.993,3.786v0.067
			c0,2.212,1.207,3.451,6.367,4.557c5.227,1.14,7.64,3.05,7.64,6.635v0.067c0,4.021-3.351,6.635-8.008,6.635
			C1988.213,2339.999,1985.164,2338.759,1982.349,2336.246z"
        />
        <path d="M2005.332,2316.208h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V2316.208z" />
      </g>
      <g>
        <g>
          <path
            d="M2822.535,787.933h9.985c2.681,0,4.792,0.771,6.132,2.077c0.972,1.005,1.508,2.245,1.508,3.753v0.067
				c0,3.049-1.876,4.624-3.72,5.428c2.781,0.837,5.026,2.446,5.026,5.663v0.067c0,4.021-3.384,6.4-8.511,6.4h-10.421V787.933z
				 M2837.479,794.131c0-2.312-1.843-3.82-5.194-3.82h-7.137v8.075h6.936c3.183,0,5.395-1.44,5.395-4.188V794.131z
				 M2832.554,800.732h-7.405v8.277h7.875c3.552,0,5.763-1.575,5.763-4.189v-0.067
				C2838.786,802.207,2836.642,800.732,2832.554,800.732z"
          />
          <path
            d="M2846.355,801.57v-13.638h2.647v13.47c0,5.06,2.714,7.908,7.171,7.908c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
				v13.437c0,6.836-3.92,10.388-9.784,10.388C2850.309,811.757,2846.355,808.205,2846.355,801.57z"
          />
          <path d="M2872.387,787.933h2.647v23.456h-2.647V787.933z" />
          <path d="M2881.901,787.933h2.647v21.009h13.202v2.446h-15.849V787.933z" />
          <path
            d="M2902.64,787.933h8.143c7.372,0,12.465,5.06,12.465,11.661v0.067c0,6.601-5.093,11.728-12.465,11.728h-8.143V787.933z
				 M2905.287,790.378v18.563h5.496c5.931,0,9.717-4.021,9.717-9.214v-0.067c0-5.194-3.787-9.282-9.717-9.282H2905.287z"
          />
          <path d="M2929.074,787.933h2.647v23.456h-2.647V787.933z" />
          <path d="M2938.588,787.933h2.479l14.777,18.798v-18.798h2.58v23.456h-2.111l-15.146-19.234v19.234h-2.58V787.933z" />
          <path
            d="M2964.017,799.728v-0.067c0-6.434,4.725-12.13,11.761-12.13c3.954,0,6.4,1.139,8.746,3.116l-1.709,2.011
				c-1.81-1.575-3.82-2.681-7.137-2.681c-5.194,0-8.913,4.39-8.913,9.617v0.067c0,5.596,3.585,9.751,9.282,9.751
				c2.681,0,5.16-1.039,6.769-2.346v-5.83h-7.137v-2.379h9.684v9.349c-2.178,1.943-5.462,3.585-9.416,3.585
				C2968.574,811.79,2964.017,806.429,2964.017,799.728z"
          />
          <path d="M3004.054,790.513l-4.423,1.374l-0.637-2.145l5.73-1.977h1.944v23.624h-2.614V790.513z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M2814.552,1468.579h16.854v2.446h-14.208v8.377h12.7v2.413h-12.7v10.22h-2.647V1468.579z" />
          <path
            d="M2836.161,1482.216v-13.638h2.647v13.47c0,5.06,2.714,7.908,7.171,7.908c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
				v13.437c0,6.836-3.92,10.388-9.784,10.388C2840.115,1492.403,2836.161,1488.851,2836.161,1482.216z"
          />
          <path d="M2868.158,1471.024h-7.875v-2.446h18.429v2.446h-7.874v21.01h-2.681V1471.024z" />
          <path
            d="M2883.3,1482.216v-13.638h2.647v13.47c0,5.06,2.714,7.908,7.171,7.908c4.323,0,7.07-2.613,7.07-7.74v-13.638h2.647
				v13.437c0,6.836-3.92,10.388-9.784,10.388C2887.254,1492.403,2883.3,1488.851,2883.3,1482.216z"
          />
          <path
            d="M2909.097,1468.579h10.086c2.882,0,5.194,0.871,6.668,2.345c1.14,1.139,1.81,2.781,1.81,4.625v0.067
				c0,3.887-2.681,6.166-6.367,6.835l7.205,9.583h-3.25l-6.802-9.114h-6.702v9.114h-2.647V1468.579z M2918.949,1480.541
				c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.881-2.211-4.624-5.998-4.624h-7.238v9.517H2918.949z"
          />
          <path d="M2933.32,1468.579h16.955v2.412h-14.308V1479h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V1468.579z" />
          <path
            d="M2805.288,1508.783h10.086c2.882,0,5.194,0.871,6.668,2.345c1.14,1.139,1.81,2.781,1.81,4.625v0.067
				c0,3.887-2.681,6.166-6.367,6.835l7.205,9.583h-3.25l-6.802-9.114h-6.702v9.114h-2.647V1508.783z M2815.14,1520.745
				c3.518,0,6.031-1.81,6.031-4.825v-0.067c0-2.881-2.211-4.624-5.998-4.624h-7.238v9.517H2815.14z"
          />
          <path d="M2829.511,1508.783h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V1508.783z" />
          <path d="M2851.958,1508.783h2.647v21.009h13.202v2.446h-15.849V1508.783z" />
          <path d="M2872.696,1508.783h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V1508.783z" />
          <path
            d="M2903.822,1508.615h2.479l10.689,23.624h-2.848l-2.748-6.199h-12.767l-2.781,6.199h-2.714L2903.822,1508.615z
				 M2910.356,1523.626l-5.328-11.929l-5.361,11.929H2910.356z"
          />
          <path
            d="M2919.601,1528.82l1.642-1.943c2.446,2.211,4.792,3.317,8.042,3.317c3.149,0,5.227-1.675,5.227-3.988v-0.067
				c0-2.178-1.173-3.417-6.098-4.457c-5.395-1.173-7.875-2.915-7.875-6.769v-0.067c0-3.686,3.25-6.4,7.707-6.4
				c3.418,0,5.864,0.972,8.243,2.882l-1.542,2.044c-2.178-1.776-4.356-2.546-6.769-2.546c-3.049,0-4.993,1.675-4.993,3.786v0.067
				c0,2.211,1.206,3.451,6.367,4.557c5.227,1.14,7.64,3.049,7.64,6.635v0.067c0,4.021-3.351,6.634-8.008,6.634
				C2925.464,1532.573,2922.416,1531.333,2919.601,1528.82z"
          />
          <path d="M2942.584,1508.783h16.955v2.412h-14.308v8.009h12.8v2.413h-12.8v8.209h14.476v2.413h-17.123V1508.783z" />
        </g>
      </g>
      {/*<g className={S.Club}>*/}
      {/*  <path*/}
      {/*    d="M4668.813,1136.18v21.144h2.981v0.502h-9.248v-0.502h2.982v-21.144h-4.657c-1.575,0-2.011,0.67-2.279,3.116*/}
      {/*	l-0.302,2.647h-0.502c0,0,0.201-3.016,0.201-4.155v-2.111h18.362v2.111c0,1.139,0.134,4.155,0.134,4.155h-0.502l-0.235-2.647*/}
      {/*	c-0.201-2.446-0.703-3.116-2.278-3.116H4668.813z"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4679.398,1157.324h2.982v-21.144h-2.982v-0.502h9.248v0.502h-2.982v10.253h11.795v-10.253h-2.981v-0.502*/}
      {/*	h9.248v0.502h-2.982v21.144h2.982v0.502h-9.248v-0.502h2.981v-10.388h-11.795v10.388h2.982v0.502h-9.248V1157.324z"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4706.903,1157.324h2.982v-21.144h-2.982v-0.502h16.854c0,0-0.703,2.647-0.972,6.266h-0.503v-3.619*/}
      {/*	c0-2.078-0.335-2.145-1.508-2.145h-7.606v10.455h5.361v-2.949h0.503v6.702h-0.503v-3.25h-5.361v10.187h7.405*/}
      {/*	c1.877,0,2.045-1.776,2.748-6.132h0.536c0,0-0.503,3.049-0.503,6.635h-16.452V1157.324z"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4626.098,1177.725c1.005-0.033,1.307-0.771,1.642-1.843h0.436c0,0-0.637,3.25-0.637,4.691v1.977h-0.502*/}
      {/*	v-1.039c0-3.217-2.614-5.361-6.199-5.361c-5.663,0-6.803,5.495-6.803,10.856c0,5.596,1.273,10.756,6.534,10.756*/}
      {/*	c1.608,0,2.982-0.402,4.021-1.072c1.843-1.206,2.781-3.317,2.781-5.261h0.536c0,2.479-1.676,6.835-7.339,6.835*/}
      {/*	c-6.064,0-10.119-4.858-10.119-11.426c0-6.635,4.256-11.191,10.388-11.191C4623.35,1175.647,4625.059,1176.585,4626.098,1177.725z*/}
      {/*	"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4643.552,1197.528h2.346l-3.149-7.573h-7.707l-2.077,5.596c-0.436,1.206,0.268,1.977,1.81,1.977h1.005v0.502*/}
      {/*	h-6.199v-0.502h0.67c1.039,0,1.643-0.503,2.179-1.977l7.405-19.804h0.502l9.048,21.781h1.642v0.502h-7.473V1197.528z*/}
      {/*	 M4635.242,1189.453h7.271l-3.854-9.215L4635.242,1189.453z"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4671.561,1195.25h0.503c-0.034,2.044-1.039,2.949-2.781,2.949c-3.786,0-4.188-3.719-4.323-5.596*/}
      {/*	c-0.134-1.943-0.469-5.361-3.316-5.361h-2.982v10.287h2.948v0.502h-9.215v-0.502h2.982v-21.144h-2.982v-0.502h8.947*/}
      {/*	c5.763,0,7.941,2.312,7.941,5.629c0,2.647-1.642,4.624-5.965,5.428v0.067c3.016,0.704,5.228,2.513,5.361,6.099*/}
      {/*	c0.101,2.714,0.134,4.356,1.441,4.356C4670.991,1197.461,4671.561,1196.489,4671.561,1195.25z M4661.575,1186.738*/}
      {/*	c3.149,0,4.155-1.675,4.155-5.026c0-3.452-1.106-5.328-4.323-5.328h-2.747v10.354H4661.575z"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4674.405,1197.528h2.982v-21.144h-2.982v-0.502h9.65c7.841,0,11.158,4.222,11.158,10.723*/}
      {/*	c0,6.333-3.083,11.426-11.158,11.426h-9.65V1197.528z M4680.671,1176.384v21.144h3.452c5.025,0,7.506-3.652,7.506-10.555*/}
      {/*	c0-7.205-2.48-10.589-7.506-10.589H4680.671z"*/}
      {/*  />*/}
      {/*  <path d="M4698.662,1197.528h2.982v-21.144h-2.982v-0.502h9.248v0.502h-2.982v21.144h2.982v0.502h-9.248V1197.528z" />*/}
      {/*  <path*/}
      {/*    d="M4714.341,1177.926c-0.703-0.938-1.105-1.542-2.747-1.542h-0.938v-0.502h6.199l12.599,16.788v-14.274*/}
      {/*	c0-1.273-0.536-2.011-2.011-2.011h-1.005v-0.502h6.567v0.502h-1.038c-1.475,0-1.978,0.737-1.978,2.011v19.803h-0.503*/}
      {/*	l-14.609-19.535v16.854c0,1.273,0.503,2.011,1.978,2.011h1.038v0.502h-6.567v-0.502h1.005c1.475,0,2.011-0.737,2.011-2.011*/}
      {/*	V1177.926z"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4748.013,1197.528h2.346l-3.149-7.573h-7.707l-2.077,5.596c-0.436,1.206,0.268,1.977,1.81,1.977h1.005v0.502*/}
      {/*	h-6.199v-0.502h0.67c1.039,0,1.643-0.503,2.179-1.977l7.405-19.804h0.502l9.048,21.781h1.642v0.502h-7.473V1197.528z*/}
      {/*	 M4739.703,1189.453h7.271l-3.854-9.215L4739.703,1189.453z"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4763.188,1197.528h7.171c1.877,0,2.044-1.776,2.748-6.132h0.536c0,0-0.503,2.949-0.503,6.635h-16.218v-0.502*/}
      {/*	h2.982v-21.144h-2.982v-0.502h9.248v0.502h-2.982V1197.528z"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4665.362,1217.929c1.005-0.033,1.307-0.771,1.642-1.843h0.436c0,0-0.637,3.25-0.637,4.691v1.977h-0.502*/}
      {/*	v-1.039c0-3.217-2.614-5.361-6.199-5.361c-5.663,0-6.803,5.495-6.803,10.856c0,5.596,1.273,10.756,6.534,10.756*/}
      {/*	c1.608,0,2.982-0.402,4.021-1.072c1.843-1.206,2.781-3.317,2.781-5.261h0.536c0,2.479-1.676,6.835-7.339,6.835*/}
      {/*	c-6.064,0-10.119-4.858-10.119-11.426c0-6.635,4.256-11.191,10.388-11.191C4662.614,1215.852,4664.323,1216.79,4665.362,1217.929z*/}
      {/*	"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4676.919,1237.732h7.171c1.877,0,2.044-1.776,2.748-6.132h0.536c0,0-0.503,2.949-0.503,6.635h-16.218v-0.502*/}
      {/*	h2.982v-21.144h-2.982v-0.502h9.248v0.502h-2.982V1237.732z"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4692.195,1216.588h-2.982v-0.502h9.282v0.502h-2.982v12.331c0,6.434,1.475,9.047,6.065,9.047*/}
      {/*	c4.791,0,7.069-3.016,7.069-8.276v-11.091c0-1.273-0.536-2.011-2.01-2.011h-1.006v-0.502h6.534v0.502h-1.038*/}
      {/*	c-1.475,0-1.978,0.737-1.978,2.011v11.158c0,4.859-2.077,8.712-7.572,8.712c-7.271,0-9.383-3.518-9.383-9.55V1216.588z"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M4714.877,1237.732h2.982v-21.144h-2.982v-0.502h8.98c5.462,0,7.874,2.446,7.874,5.328*/}
      {/*	c0,2.279-1.675,4.356-5.395,5.294v0.067c4.322,0.804,6.434,2.681,6.434,5.496c0,3.217-2.514,5.964-8.545,5.964h-9.349V1237.732z*/}
      {/*	 M4723.891,1226.641c2.513,0,4.39-0.972,4.39-4.792c0-3.887-1.843-5.261-4.523-5.261h-2.614v10.053H4723.891z M4724.192,1237.732*/}
      {/*	c3.384,0,5.127-2.011,5.127-5.629c0-3.585-1.844-4.959-5.194-4.959h-2.982v10.589H4724.192z"*/}
      {/*  />*/}
      {/*</g>*/}
      {/*<g className={S.Label}>*/}
      {/*  <text transform="matrix(1 0 0 1 1871.5889 792.713)">*/}
      {/*    <tspan x="0" y="0">*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.499" y="40.204">*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*  <text transform="matrix(1 0 0 1 938.6551 842.5988)">*/}
      {/*    <tspan x="0" y="0">*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.499" y="40.204">*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*  <text transform="matrix(1 0 0 1 1646.0721 1493.0042)">*/}
      {/*    <tspan x="0" y="0">*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.499" y="40.204">*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*  <text transform="matrix(1 0 0 1 757.4328 1535.493)">*/}
      {/*    <tspan x="0" y="0">*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.499" y="40.204">*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*  <text transform="matrix(1 0 0 1 933.133 2299.46)">*/}
      {/*    <tspan x="0" y="0">*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.499" y="40.204">*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*  <text transform="matrix(1 0 0 1 1873.9825 2299.4597)">*/}
      {/*    <tspan x="0" y="0">*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.499" y="40.204">*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*  <text transform="matrix(1 0 0 1 2819.2173 811.3882)">BUILDING 1</text>*/}
      {/*  /!* Building 2 *!/*/}
      {/*  <text transform="matrix(1 0 0 1 2811.2344 1492.0342)">*/}
      {/*    <tspan x="0" y="0">*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.499" y="40.204">*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*</g>*/}
    </g>
  );
};

export default BuildingLabels;
