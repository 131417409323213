import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building12 = ({ unitClass, click }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 12" });
  return (
    <g id="BUILDING-12">
      <g
        onClick={() => click("A4")}
        className={unitClass(b, "132")}
        id="UNIT-132-A4"
      >
        <polygon
          className={S.Hot}
          points="3614.146,2367.874 3604.12,2367.874 3604.12,2408.886 3614.146,2408.886 3711.665,2408.886
				3747.209,2408.886 3747.209,2316.835 3614.146,2316.835 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3660.0867 2355.8257)"
            className={S.Number}
          >
            132
          </text>
          <text
            transform="matrix(1 0 0 1 3662.2427 2385.2046)"
            className={S.Plan}
          >
            A4
          </text>
        </g>
      </g>
      <g
        onClick={() => click("A")}
        className={unitClass(b, "133")}
        id="UNIT-133-Aa"
      >
        <polygon
          className={S.Hot}
          points="3614.146,2276.734 3604.12,2276.734 3604.12,2316.835 3614.146,2316.835 3747.209,2316.835
				3750.399,2316.835 3750.399,2225.924 3614.146,2225.924 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3660.0222 2265.7283)"
            className={S.Number}
          >
            133
          </text>
          <text
            transform="matrix(1 0 0 1 3663.4399 2295.1072)"
            className={S.Plan}
          >
            Aa
          </text>
        </g>
      </g>
      <g
        onClick={() => click("A")}
        className={unitClass(b, "134")}
        id="UNIT-134-Aa"
      >
        <polygon
          className={S.Hot}
          points="3747.209,2135.013 3614.146,2135.013 3614.146,2185.139 3604.12,2185.139 3604.12,2225.924
				3614.146,2225.924 3750.399,2225.924 3750.399,2135.013 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3659.1899 2175.6313)"
            className={S.Number}
          >
            134
          </text>
          <text
            transform="matrix(1 0 0 1 3663.4397 2205.0103)"
            className={S.Plan}
          >
            Aa
          </text>
        </g>
      </g>
      <g
        onClick={() => click("A2")}
        className={unitClass(b, "135")}
        id="UNIT-135-A2"
      >
        <polygon
          className={S.Hot}
          points="3747.209,2043.873 3614.146,2043.873 3614.146,2095.367 3604.12,2095.367 3604.12,2135.013
				3614.146,2135.013 3747.209,2135.013 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3659.957 2085.5352)"
            className={S.Number}
          >
            135
          </text>
          <text
            transform="matrix(1 0 0 1 3663.1794 2114.9141)"
            className={S.Plan}
          >
            A2
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building12;
