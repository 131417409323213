import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};
const Building9A = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 9A" });
  return (
    <g id="BUILDING-9A">
      <g className={unitClass(b, "76")} id="UNIT-76-F1">
        <polygon
          className={S.Hot}
          points="5015.834,2390.712 4937.782,2390.712 4937.782,2412.893 4926.704,2412.893 4926.704,2463.114
				5111.355,2463.114 5111.571,2371.255 5015.834,2371.255 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5042.7153 2412.9895)"
            className={S.Number}
          >
            76
          </text>
          <text
            transform="matrix(1 0 0 1 5045.9766 2442.3687)"
            className={S.Plan}
          >
            F1
          </text>
        </g>
      </g>
      <g className={unitClass(b, "77")} id="UNIT-77-G">
        <polygon
          className={S.Hot}
          points="4926.868,2370.099 4937.782,2370.099 4937.782,2390.712 5015.834,2390.712 5015.834,2371.255
				5111.571,2371.255 5111.689,2320.824 4926.868,2320.827 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4964.2671 2351.8052)"
            className={S.Number}
          >
            77
          </text>
          <text
            transform="matrix(1 0 0 1 4969.9072 2381.1843)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g className={unitClass(b, "78")} id="UNIT-78-F">
        <polygon
          className={S.Hot}
          points="5015.834,2250.478 4937.646,2250.478 4937.646,2270.851 4926.868,2270.851 4926.868,2320.827
				5111.689,2320.824 5111.9,2231.021 5015.834,2231.021 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5043.0532 2271.843)"
            className={S.Number}
          >
            78
          </text>
          <text
            transform="matrix(1 0 0 1 5050.6299 2301.2222)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g className={unitClass(b, "79")} id="UNIT-79-G">
        <polygon
          className={S.Hot}
          points="4927.168,2179.113 4927.168,2228.946 4937.646,2228.946 4937.646,2250.478 5015.834,2250.478
				5015.834,2231.021 5111.9,2231.021 5112.022,2179.11 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4963.6567 2210.6587)"
            className={S.Number}
          >
            79
          </text>
          <text
            transform="matrix(1 0 0 1 4969.9072 2240.0378)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g className={unitClass(b, "80")} id="UNIT-80-F">
        <polygon
          className={S.Hot}
          points="5015.834,2086.92 5015.834,2106.377 4937.646,2106.377 4937.646,2129.192 4927.168,2129.192
				4927.168,2179.113 5112.022,2179.11 5112.239,2086.92 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5041.5581 2130.6965)"
            className={S.Number}
          >
            80
          </text>
          <text
            transform="matrix(1 0 0 1 5050.6299 2160.0757)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g className={unitClass(b, "81")} id="UNIT-81-G1">
        <polygon
          className={S.Hot}
          points="5015.834,2086.92 5112.239,2086.92 5112.354,2037.843 4926.868,2037.843 4926.868,2086.401
				4937.646,2086.401 4937.646,2106.377 5015.834,2106.377 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4966.7632 2069.5122)"
            className={S.Number}
          >
            81
          </text>
          <text
            transform="matrix(1 0 0 1 4965.2539 2098.8914)"
            className={S.Plan}
          >
            G1
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building9A;
