export type T__PageKey =
  | "SITEMAP"
  | "CONDOMINIUM-PLAN-TYPE"
  | "CONDOMINIUM-KEY-PLAN";

export interface I__Page {
  title: string;
  pathname: string;
}

const routes: { [key in T__PageKey]: I__Page } = {
  SITEMAP: {
    title: "Sitemap",
    pathname: "/sitemap",
  },
  "CONDOMINIUM-PLAN-TYPE": {
    title: "Condominium plan type",
    pathname: "/condominium-plan-type",
  },
  "CONDOMINIUM-KEY-PLAN": {
    title: "Condominium key plan",
    pathname: "/condominium-key-plan",
  },
};

export default routes;
