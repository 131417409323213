import S from "../SVG.module.sass";

const Compass = () => {
  return (
    <g id="COMPASS" className={S.Compass}>
      <path
        d="M6100.579,178.728L6205.79,283.94c0.248-2.951,0.381-5.935,0.381-8.951
			c0-58.318-47.276-105.593-105.593-105.593c-58.318,0-105.593,47.276-105.593,105.593c0,3.016,0.133,6,0.381,8.951
			L6100.579,178.728z"
      />
      <path d="M6110.579,217.013v163.095c42.554-3.998,77.759-33.246,90.485-72.609L6110.579,217.013z" />
      <path d="M6090.579,217.012l-90.486,90.486c12.726,39.363,47.932,68.611,90.486,72.609V217.012z" />
    </g>
  );
};

export default Compass;
