import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building18 = ({ unitClass, click }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 18" });
  return (
    <g id="BUILDING-18">
      <g
        onClick={() => click("B2")}
        className={unitClass(b, "107")}
        id="UNIT-107-B2"
      >
        <polygon
          className={S.Hot}
          points="4367.39,1778.052 4460.263,1779.052 4528.647,1779.052 4534.192,1779.052 4534.192,1718.984
				4528.647,1718.984 4528.647,1687.385 4367.39,1687.385 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4425.4131 1727.0259)"
            className={S.Number}
          >
            107
          </text>
          <text
            transform="matrix(1 0 0 1 4430.7158 1756.4049)"
            className={S.Plan}
          >
            B2
          </text>
        </g>
      </g>
      <g
        onClick={() => click("B")}
        className={unitClass(b, "108")}
        id="UNIT-108-B"
      >
        <polygon
          className={S.Hot}
          points="4367.39,1687.385 4528.647,1687.385 4534.192,1687.385 4534.192,1627.318 4528.647,1627.318
				4528.647,1595.987 4367.39,1595.987 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4425.1396 1636.5868)"
            className={S.Number}
          >
            108
          </text>
          <text
            transform="matrix(1 0 0 1 4438.5029 1665.9658)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g
        onClick={() => click("B")}
        className={unitClass(b, "109")}
        id="UNIT-109-B"
      >
        <polygon
          className={S.Hot}
          points="4367.39,1595.987 4528.647,1595.987 4534.192,1595.987 4534.192,1536.92 4528.647,1536.92
				4528.647,1506.589 4367.39,1506.589 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4424.8022 1546.1477)"
            className={S.Number}
          >
            109
          </text>
          <text
            transform="matrix(1 0 0 1 4438.5029 1575.5267)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g
        onClick={() => click("B")}
        className={unitClass(b, "110")}
        id="UNIT-110-B"
      >
        <polygon
          className={S.Hot}
          points="4367.39,1414.95 4367.39,1506.589 4528.647,1506.589 4534.192,1506.589 4534.192,1444.522
				4528.647,1444.522 4528.647,1414.95 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4428.1812 1455.7087)"
            className={S.Number}
          >
            110
          </text>
          <text
            transform="matrix(1 0 0 1 4438.5024 1485.0878)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g
        onClick={() => click("B1")}
        className={unitClass(b, "111")}
        id="UNIT-111-B1"
      >
        <polygon
          className={S.Hot}
          points="4534.192,1414.95 4534.192,1354.883 4528.647,1354.883 4528.647,1323.001 4367.39,1322.001
				4367.39,1414.95 4528.647,1414.95 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4432.4463 1365.2697)"
            className={S.Number}
          >
            111
          </text>
          <text
            transform="matrix(1 0 0 1 4433.8486 1394.6488)"
            className={S.Plan}
          >
            B1
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building18;
