import { T__Plan_Name } from "./floorplans/interface";

export type T__Building_Type = "townhome" | "condominiums" | "Condominiums";

export type T__Buildings_Title =
  | "Building 1"
  | "Building 2"
  | "Building 3"
  | "Building 4"
  | "Building 5"
  | "Building 6"
  | "Building 7"
  | "Building 8"
  | "Building 8A"
  | "Building 8B"
  | "Building 9A"
  | "Building 9B"
  | "Building 10"
  | "Building 11"
  | "Building 12"
  | "Building 13"
  | "Building 14"
  | "Building 15"
  | "Building 16"
  | "Building 17"
  | "Building 18"
  | "Building 19"
  | "Building 20"
  | "Building 20A"
  | "Building 20B"
  | "Building 21"
  | "Building 22"
  | undefined;

export interface I__Buildings {
  title: T__Buildings_Title;
  plans: Array<T__Plan_Name>;
  scale: number;
  x: number;
  y: number;
  drawer: "left" | "right";
  future: boolean;
}

const Buildings: Array<I__Buildings> = [
  {
    title: "Building 1",
    plans: ["F", "F2", "G", "G1"],
    scale: 5.836,
    x: -4735.88,
    y: -615.34,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 2",
    plans: ["A", "A1", "Aa", "A5"],
    scale: 5.836,
    x: -5351.88,
    y: -579.34,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 3",
    plans: ["F1", "G", "F", "G1"],
    scale: 5.836,
    x: -5921.88,
    y: -579.34,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 4",
    plans: ["A1", "Aa", "A2"],
    scale: 5.836,
    x: -6642.53,
    y: -579.34,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 5",
    plans: ["F", "G", "G1"],
    scale: 5.836,
    x: -7211.53,
    y: -880.34,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 6",
    plans: ["A", "A1", "A2", "Aa"],
    scale: 4.019,
    x: -4588.88,
    y: -993.66,
    drawer: "left",
    future: true,
  },
  {
    title: "Building 7",
    plans: ["G", "G1", "F", "F2"],
    scale: 4.019,
    x: -4588.88,
    y: -1655.66,
    drawer: "left",
    future: true,
  },
  {
    title: "Building 8A",
    // plans: ['A', 'A2', 'A4'],
    plans: ["A"],
    scale: 4.87041,
    x: -11465.5,
    y: -4507.7,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 8B",
    // plans: ['A', 'A2', 'A4'],
    plans: ["A"],
    scale: 4.87041,
    x: -11465.5,
    y: -4507.7,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 9A",
    plans: ["G", "F"],
    scale: 4.87041,
    x: -10337.5,
    y: -4507.7,
    drawer: "right",
    future: false,
  },
  {
    title: "Building 9B",
    plans: ["G", "F"],
    scale: 4.87041,
    x: -9534.5,
    y: -4316.7,
    drawer: "right",
    future: false,
  },
  {
    title: "Building 10",
    plans: ["G", "F"],
    scale: 4.87041,
    x: -9080.51,
    y: -4507.7,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 11",
    // plans: ['C', 'C2'],
    plans: ["C", "C2"],
    scale: 4.87041,
    x: -8089.62,
    y: -4507.7,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 12",
    plans: ["C", "C2"],
    scale: 5.309,
    x: -4082.14,
    y: -2460.39,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 13",
    plans: ["Aa", "A2", "A4"],
    scale: 5.309,
    x: -3372.06,
    y: -2437.67,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 14",
    plans: ["C", "C2"],
    scale: 4.979,
    x: -2825.94,
    y: -2310.32,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 15",
    plans: ["H", "H1", "H2"],
    scale: 4.979,
    x: -2445.94,
    y: -2324.32,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 16",
    plans: ["A", "A2", "A4"],
    scale: 4.979,
    x: -7065.6,
    y: -2800.36,
    drawer: "right",
    future: false,
  },
  {
    title: "Building 17",
    plans: ["D", "D1"],
    scale: 4.979,
    x: -8308.23,
    y: -3048.95,
    drawer: "right",
    future: false,
  },
  {
    title: "Building 18",
    plans: ["B", "B1", "B2"],
    scale: 4.979,
    x: -9411.31,
    y: -3000.55,
    drawer: "right",
    future: false,
  },
  {
    title: "Building 19",
    plans: ["B", "B1", "B2"],
    scale: 4.979,
    x: -5279.72,
    y: -1497.49,
    drawer: "right",
    future: true,
  },

  {
    title: "Building 20A",
    // plans: ['A', 'A2', 'A4'],
    plans: ["A"],
    scale: 4.979,
    x: -12210.4,
    y: -2999.3,
    drawer: "right",
    future: false,
  },
  {
    title: "Building 20B",
    // plans: ['A', 'A2', 'A4'],
    plans: ["A"],
    scale: 4.979,
    x: -11674.4,
    y: -2999.3,
    drawer: "right",
    future: false,
  },
  // {
  //   title: "Building 20",
  //   plans: ["D", "D2"],
  //   scale: 4.979,
  //   x: -5828.41,
  //   y: -1478.49,
  //   drawer: "right",
  //   future: true,
  // },
  {
    title: "Building 21",
    plans: ["F", "F1", "G", "G1"],
    scale: 6.579,
    x: -7452.05,
    y: -1354.59,
    drawer: "right",
    future: true,
  },
  {
    title: "Building 22",
    plans: ["A3", "F", "G", "B3"],
    scale: 5.685,
    x: -10199.7,
    y: -2040.78,
    drawer: "right",
    future: false,
  },
];

export default Buildings;
