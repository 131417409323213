import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building19 = ({ unitClass, click }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 19" });
  return (
    <g id="BUILDING-19">
      <g
        onClick={() => click("B1")}
        className={unitClass(b, "80")}
        id="UNIT-80-B1"
      >
        <polygon
          className={S.Hot}
          points="4909.5,1323.001 4909.5,1354.883 4903.955,1354.883 4903.955,1414.95 4909.5,1414.95
				5070.758,1414.95 5070.758,1322.001 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4969.6968 1365.2699)"
            className={S.Number}
          >
            80
          </text>
          <text
            transform="matrix(1 0 0 1 4973.2573 1394.6489)"
            className={S.Plan}
          >
            B1
          </text>
        </g>
      </g>
      <g
        onClick={() => click("B")}
        className={unitClass(b, "81")}
        id="UNIT-81-B"
      >
        <polygon
          className={S.Hot}
          points="4909.5,1444.522 4903.955,1444.522 4903.955,1506.589 4909.5,1506.589 5070.758,1506.589
				5070.758,1414.95 4909.5,1414.95 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4973.96 1455.709)"
            className={S.Number}
          >
            81
          </text>
          <text
            transform="matrix(1 0 0 1 4977.9111 1485.088)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g
        onClick={() => click("B")}
        className={unitClass(b, "82")}
        id="UNIT-82-B"
      >
        <polygon
          className={S.Hot}
          points="4909.5,1536.92 4903.955,1536.92 4903.955,1595.987 4909.5,1595.987 5070.758,1595.987
				5070.758,1506.589 4909.5,1506.589 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4971.1396 1546.1479)"
            className={S.Number}
          >
            82
          </text>
          <text
            transform="matrix(1 0 0 1 4977.9116 1575.527)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g
        onClick={() => click("B")}
        className={unitClass(b, "83")}
        id="UNIT-83-B"
      >
        <polygon
          className={S.Hot}
          points="4909.5,1627.318 4903.955,1627.318 4903.955,1687.385 4909.5,1687.385 5070.758,1687.385
				5070.758,1595.987 4909.5,1595.987 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4971.0747 1636.587)"
            className={S.Number}
          >
            83
          </text>
          <text
            transform="matrix(1 0 0 1 4977.9116 1665.9661)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g
        onClick={() => click("B2")}
        className={unitClass(b, "84")}
        id="UNIT-84-B2"
      >
        <polygon
          className={S.Hot}
          points="4909.5,1718.984 4903.955,1718.984 4903.955,1779.052 4909.5,1779.052 4977.884,1779.052
				5070.758,1778.052 5070.758,1687.385 4909.5,1687.385 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4970.2427 1727.0261)"
            className={S.Number}
          >
            84
          </text>
          <text
            transform="matrix(1 0 0 1 4970.1245 1756.4052)"
            className={S.Plan}
          >
            B2
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building19;
