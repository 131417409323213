import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building9B = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 9B" });

  console.log("b=", b);
  return (
    <g id="BUILDING-9B">
      <g className={unitClass(b, "85")} id="UNIT-85-A2">
        <polygon
          className={S.Hot}
          points="4628.284,2145.083 4628.284,2049.285 4536.46,2049.285 4537.459,2233.056 4586.833,2233.056
				4586.847,2222.222 4608.827,2222.222 4608.827,2145.083 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4566.085 2100.124)"
            className={S.Number}
          >
            85
          </text>
          <text
            transform="matrix(1 0 0 1 4569.1909 2129.5032)"
            className={S.Plan}
          >
            F1
          </text>
        </g>
      </g>
      <g className={unitClass(b, "84")} id="UNIT-84-G">
        <polygon
          className={S.Hot}
          points="4628.284,2049.285 4628.284,2145.083 4608.827,2145.083 4608.827,2222.222 4629.148,2222.222
				4629.148,2233.491 4680.201,2233.491 4680.199,2049.285 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4627.459 2173.3301)"
            className={S.Number}
          >
            84
          </text>
          <text
            transform="matrix(1 0 0 1 4634.3213 2202.709)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g className={unitClass(b, "83")} id="UNIT-83-G">
        <polygon
          className={S.Hot}
          points="4732.11,2145.083 4732.11,2049.285 4680.199,2049.285 4680.201,2233.491 4731.251,2233.491
				4731.336,2222.222 4751.567,2222.222 4751.567,2145.083 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4699.6602 2173.3298)"
            className={S.Number}
          >
            83
          </text>
          <text
            transform="matrix(1 0 0 1 4705.6904 2202.709)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g className={unitClass(b, "82")} id="UNIT-82-A2">
        <polygon
          className={S.Hot}
          points="4732.11,2049.285 4732.11,2145.083 4751.567,2145.083 4751.567,2222.222 4772.881,2222.222
				4772.659,2232.927 4823.938,2233.056 4823.938,2049.285 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4761.8652 2097.124)"
            className={S.Number}
          >
            82
          </text>
          <text
            transform="matrix(1 0 0 1 4764.8413 2126.5034)"
            className={S.Plan}
          >
            F1
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building9B;
