import Units from "./Units";
import Outline from "./Outline";
import BuildingLabels from "./BuildingLabels";
// import StreetLabels from "./StreetLabels";
// import OtherLabels from "./OtherLabels";
import Compass from "./Compass";
import S from "./SVG.module.sass";
// import BuildingAreas from "./BuildingAreas";
import CondoBuildings from "./CondoBuildings";

const SVG = () => {
  return (
    <svg className={S.Siteplan} viewBox="0 0 6467 3146">
      <Units />
      <Outline />
      <BuildingLabels />
      {/*<StreetLabels />*/}
      {/*<OtherLabels />*/}
      <Compass />
      <CondoBuildings />
      {/*<BuildingAreas />*/}
    </svg>
  );
};

export default SVG;
