import { Provider } from "react-redux";
import styled from "styled-components";
import S from "./Root.module.sass";
import Routes from "../__Routes";
import { Store } from "../../store";

// @ts-ignore
import F from "../../fonts/HelveticaNeueLTStd-Md.otf";
// @ts-ignore
import FL from "../../fonts/HelveticaNeueLTStd-Lt.otf";
// @ts-ignore
import GothamBook from "../../fonts/Gotham-Book.otf";
// @ts-ignore
import GothamLight from "../../fonts/Gotham-Light.otf";
// @ts-ignore
import GothamMedium from "../../fonts/Gotham-Medium.otf";

type Props = {
  store: Store;
};

const AppRoot = styled.div`
  @font-face {
    font-family: "Helvetica Neue LT Std";
    font-weight: 500;
    src: url(${F}) format("opentype");
    style: normal;
  }

  @font-face {
    font-family: "Helvetica Neue LT Std";
    font-weight: 300;
    src: url(${FL}) format("opentype");
    style: normal;
  }

  @font-face {
    font-family: "Gotham";
    font-weight: 300;
    src: url(${GothamLight}) format("opentype");
    style: normal;
  }

  @font-face {
    font-family: "Gotham";
    font-weight: 400;
    src: url(${GothamBook}) format("opentype");
    style: normal;
  }

  @font-face {
    font-family: "Gotham";
    font-weight: 500;
    src: url(${GothamMedium}) format("opentype");
    style: normal;
  }
`;

const Root = ({ store }: Props) => {
  return (
    <AppRoot>
      <div className={S.Root}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </div>
    </AppRoot>
  );
};

export default Root;
