import classNames from "classnames";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
import S from "./CondoBuildings.module.sass";
// import R from "../../../../constants/routes";
// import { updateCondoBuilding } from "../../../../store/condominiumsSlice";
// import { T__Buildings_Title } from "../../../../constants/buildings";

const CondoBuildings = () => {
  // const navigation = useNavigate();
  // const dispatch = useDispatch();

  // const click = (pathname: string, condoBuilding: T__Buildings_Title) => {
  //   dispatch(updateCondoBuilding({ condoBuilding }));
  //   navigation(pathname);
  // };

  const buildingStyle = (disable = true, soldout = false) => {
    const classes = [
      S.BuildingBlock,
      { [`${S.Disable}`]: !soldout && disable, [`${S.SoldOut}`]: soldout },
    ];
    return classNames(classes);
  };
  return (
    <g id="OTHER_BUILDINGS">
      <g>
        <g>
          <g>
            <polygon
              fill="#CFD0CE"
              points="1392.792,1730.995 1392.792,1686.715 1348.512,1686.715 1348.512,1625.184 1366.679,1625.184
					1366.679,1585.664 1348.512,1585.664 1348.512,1475.311 1366.679,1475.311 1366.679,1435.792 1348.512,1435.792
					1348.512,1374.261 1392.792,1374.261 1392.792,1329.981 1493.306,1329.981 1493.306,1343.605 1584.883,1343.605
					1584.879,1361.771 1627.428,1361.771 1627.427,1343.614 1776.388,1343.605 1776.388,1361.771 1862.839,1361.771
					1862.839,1343.605 1958.06,1343.61 1958.06,1348.143 2074.249,1348.147 2074.249,1470.99 2056.08,1470.99 2056.092,1515.441
					2103.769,1515.441 2103.769,1620.483 2060.624,1620.483 2060.624,1667.413 1954.055,1667.413 1954.055,1628.053
					1916.429,1628.039 1916.433,1653.774 1823.107,1653.774 1823.104,1635.621 1779.04,1635.628 1779.039,1653.794
					1668.702,1653.788 1668.703,1635.622 1624.632,1635.619 1624.631,1653.786 1531.311,1654.076 1531.311,1635.621
					1493.306,1635.621 1493.306,1730.995 				"
            />
          </g>
          <g>
            <polygon
              fill="#CFD0CE"
              points="2073.571,1348.825 2073.571,1470.312 2055.402,1470.312 2055.414,1516.119 2103.091,1516.119
					2103.091,1619.805 2059.946,1619.805 2059.946,1666.735 1954.733,1666.735 1954.733,1627.375 1915.752,1627.361
					1915.755,1653.097 1823.785,1653.097 1823.782,1634.944 1778.362,1634.95 1778.361,1653.117 1669.38,1653.11 1669.38,1634.944
					1623.955,1634.941 1623.954,1653.11 1531.989,1653.396 1531.989,1634.943 1492.628,1634.943 1492.628,1730.317
					1393.47,1730.317 1393.47,1686.037 1349.19,1686.037 1349.19,1625.861 1367.356,1625.861 1367.356,1584.987 1349.19,1584.987
					1349.19,1475.989 1367.356,1475.989 1367.356,1435.115 1349.19,1435.115 1349.19,1374.939 1393.47,1374.939 1393.47,1330.659
					1492.628,1330.659 1492.628,1344.283 1584.205,1344.283 1584.202,1362.449 1628.106,1362.449 1628.105,1344.291
					1775.71,1344.283 1775.71,1362.449 1863.517,1362.449 1863.517,1344.283 1957.382,1344.288 1957.382,1348.821 				"
            />
          </g>
          <g>
            <path
              d="M1492.628,1330.659v13.625h91.577l-0.003,18.166h43.904l-0.001-18.158l147.605-0.008v18.166l87.807,0v-18.166
					l93.865,0.005l0,4.533l116.189,0.004v121.487h-18.169l0.012,45.807h47.677v103.686h-43.145v46.93h-105.213v-39.36l-38.981-0.014
					l0.004,25.736h-91.971l-0.003-18.153l-45.42,0.007l0,18.166l-108.982-0.006l0.001-18.166l-45.426-0.003l-0.001,18.169
					l-91.965,0.286v-18.454h-39.361v95.374h-99.158v-44.28h-44.28v-60.176h18.166v-40.874h-18.166v-108.998h18.166v-40.874h-18.166
					v-60.176h44.28v-44.28H1492.628 M1493.984,1329.303h-1.356h-99.158h-1.356v1.356v42.925h-42.925h-1.356v1.356v60.176v1.356
					h1.356H1366v38.163h-16.811h-1.356v1.356v108.998v1.356h1.356H1366v38.163h-16.811h-1.356v1.356v60.176v1.356h1.356h42.925
					v42.925v1.356h1.356h99.158h1.356v-1.356v-94.018h36.65v17.098v1.36l1.36-0.004l91.965-0.286l1.351-0.004l0-1.351l0.001-16.813
					l42.714,0.003l-0.001,16.81v1.356l1.356,0l108.982,0.006h1.356l0-1.356l0-16.811l42.709-0.006l0.002,16.797l0,1.355h1.355
					h91.971h1.356l0-1.356l-0.004-24.379l36.27,0.013v38.005v1.356h1.356h105.213h1.356v-1.356v-45.574h41.789h1.356v-1.356
					v-103.686v-1.356h-1.356h-46.322l-0.011-43.096h16.813h1.356v-1.356v-121.487v-1.356l-1.356,0l-114.833-0.004l0-3.177v-1.356
					l-1.356,0l-93.865-0.005h-1.356v1.356v16.81l-85.096,0v-16.81v-1.356h-1.356l-147.605,0.008l-1.356,0v1.356l0.001,16.802
					h-41.192l0.003-16.81l0-1.356h-1.356h-90.221v-12.269V1329.303L1493.984,1329.303z"
            />
          </g>
        </g>
        <g>
          <path d="M1659.273,1463.75h16.747v2.43h-14.117v8.324h12.619v2.397h-12.619v10.155h-2.63V1463.75z" />
          <path
            d="M1680.746,1477.3v-13.551h2.63v13.384c0,5.027,2.697,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.691v-13.551h2.63
				v13.351c0,6.792-3.896,10.321-9.722,10.321C1684.675,1487.422,1680.746,1483.893,1680.746,1477.3z"
          />
          <path d="M1712.541,1466.18h-7.824v-2.43h18.312v2.43h-7.824v20.876h-2.664V1466.18z" />
          <path
            d="M1727.589,1477.3v-13.551h2.63v13.384c0,5.027,2.697,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.691v-13.551h2.63
				v13.351c0,6.792-3.896,10.321-9.722,10.321C1731.518,1487.422,1727.589,1483.893,1727.589,1477.3z"
          />
          <path
            d="M1753.225,1463.75h10.021c2.863,0,5.161,0.866,6.625,2.331c1.132,1.132,1.798,2.763,1.798,4.595v0.066
				c0,3.862-2.664,6.126-6.326,6.792l7.158,9.522h-3.229l-6.759-9.056h-6.659v9.056h-2.63V1463.75z M1763.013,1475.636
				c3.496,0,5.993-1.798,5.993-4.794v-0.067c0-2.863-2.197-4.595-5.959-4.595h-7.192v9.456H1763.013z"
          />
          <path d="M1777.295,1463.75h16.847v2.397h-14.217v7.958h12.719v2.397h-12.719v8.157h14.383v2.397h-17.013V1463.75z" />
          <path
            d="M1650.067,1503.701h10.021c2.863,0,5.161,0.866,6.625,2.331c1.132,1.132,1.798,2.763,1.798,4.595v0.066
				c0,3.862-2.664,6.126-6.326,6.792l7.158,9.522h-3.229l-6.759-9.056h-6.659v9.056h-2.63V1503.701z M1659.855,1515.587
				c3.496,0,5.993-1.798,5.993-4.794v-0.067c0-2.863-2.197-4.595-5.959-4.595h-7.192v9.456H1659.855z"
          />
          <path d="M1674.138,1503.701h16.847v2.397h-14.217v7.958h12.719v2.397h-12.719v8.157h14.383v2.397h-17.013V1503.701z" />
          <path d="M1696.444,1503.701h2.63v20.875h13.118v2.431h-15.748V1503.701z" />
          <path d="M1717.052,1503.701h16.847v2.397h-14.217v7.958h12.719v2.397h-12.719v8.157h14.383v2.397h-17.013V1503.701z" />
          <path
            d="M1747.981,1503.535h2.464l10.621,23.473h-2.83l-2.73-6.16h-12.686l-2.764,6.16h-2.697L1747.981,1503.535z
				 M1754.474,1518.451l-5.294-11.853l-5.327,11.853H1754.474z"
          />
          <path
            d="M1763.662,1523.611l1.631-1.931c2.431,2.197,4.761,3.296,7.991,3.296c3.13,0,5.194-1.665,5.194-3.962v-0.066
				c0-2.164-1.165-3.396-6.06-4.428c-5.36-1.166-7.824-2.896-7.824-6.726v-0.066c0-3.663,3.229-6.359,7.658-6.359
				c3.396,0,5.827,0.965,8.19,2.863l-1.531,2.031c-2.164-1.764-4.329-2.53-6.726-2.53c-3.03,0-4.961,1.665-4.961,3.762v0.067
				c0,2.197,1.199,3.429,6.326,4.528c5.194,1.132,7.591,3.03,7.591,6.592v0.067c0,3.995-3.329,6.592-7.957,6.592
				C1769.488,1527.34,1766.458,1526.108,1763.662,1523.611z"
          />
          <path d="M1786.501,1503.701h16.847v2.397h-14.217v7.958h12.719v2.397h-12.719v8.157h14.383v2.397h-17.013V1503.701z" />
        </g>
      </g>
      <g>
        <g>
          <g>
            <polygon
              fill="#CFD0CE"
              points="861.464,1888.418 861.464,1881.229 773.511,1881.228 773.511,1888.416 679.42,1888.413
					679.42,1844.127 653.307,1844.127 653.31,1743.241 666.935,1743.229 666.937,1692.136 685.103,1692.138 685.167,1609.101
					667.136,1609.087 666.942,1471.499 685.108,1471.473 685.111,1388.449 666.943,1388.448 666.943,1337.356 653.323,1337.355
					653.271,1236.47 679.439,1236.471 679.47,1192.184 773.53,1192.249 773.534,1199.375 861.484,1199.326 861.484,1192.19
					955.561,1192.192 955.56,1236.473 981.674,1236.474 981.676,1337.364 977.134,1337.364 977.134,1428.595 958.972,1428.589
					958.959,1470.74 977.125,1470.745 977.125,1610.995 958.965,1610.99 958.954,1652.033 977.257,1652.033 977.125,1743.251
					981.659,1743.251 981.679,1844.136 955.542,1844.135 955.541,1888.421 				"
            />
          </g>
          <g>
            <polygon
              fill="#CFD0CE"
              points="954.883,1192.87 954.882,1237.151 980.996,1237.151 980.998,1336.686 976.457,1336.686
					976.457,1427.917 958.294,1427.911 958.281,1471.417 976.448,1471.423 976.448,1610.317 958.287,1610.312 958.276,1652.711
					976.578,1652.711 976.446,1743.929 980.981,1743.929 981.001,1843.458 954.864,1843.457 954.863,1887.743 862.142,1887.74
					862.142,1880.551 772.833,1880.55 772.833,1887.738 680.098,1887.735 680.098,1843.449 653.985,1843.449 653.988,1743.918
					667.613,1743.906 667.614,1692.813 685.78,1692.815 685.845,1608.423 667.813,1608.409 667.621,1472.176 685.786,1472.15
					685.789,1387.771 667.62,1387.77 667.62,1336.678 654,1336.678 653.949,1237.148 680.117,1237.149 680.147,1192.862
					772.853,1192.926 772.857,1200.053 862.162,1200.003 862.162,1192.868 				"
            />
          </g>
          <g>
            <path
              d="M680.147,1192.862l92.706,0.064l0.004,7.127l89.305-0.05l0-7.136l92.721,0.003l-0.001,44.28l26.114,0.001l0.002,99.535
					l-4.542,0v91.231l-18.162-0.005l-0.013,43.506l18.166,0.005v138.894l-18.161-0.005l-0.011,42.399h18.302l-0.133,91.218l4.535,0
					l0.02,99.529l-26.137-0.001l-0.001,44.286l-92.721-0.003v-7.189l-89.309-0.001v7.188l-92.735-0.003v-44.286h-26.113l0.003-99.53
					l13.625-0.012l0.001-51.093l18.166,0.002l0.066-84.392l-18.033-0.014l-0.192-136.233l18.165-0.026l0.003-84.379l-18.168-0.001
					v-51.092l-13.621,0l-0.051-99.53l26.168,0.001L680.147,1192.862 M678.792,1191.506l-0.001,1.356l-0.03,42.932l-24.813-0.001
					h-1.356l0.001,1.356l0.051,99.53l0.001,1.355H654l12.265,0v49.736v1.356l1.356,0l16.812,0.001l-0.002,81.669l-16.812,0.024
					l-1.355,0.002l0.002,1.356l0.192,136.233l0.002,1.353l1.353,0.001l16.677,0.013l-0.063,81.682l-16.811-0.001l-1.356,0v1.356
					l-0.001,49.739l-12.27,0.011l-1.354,0.001v1.354l-0.003,99.53v1.356h1.356h24.757v42.931v1.356h1.356l92.735,0.003h1.356v-1.356
					v-5.832l86.597,0.001v5.834v1.356h1.356l92.721,0.003l1.356,0v-1.356l0.001-42.931l24.781,0.001h1.356l0-1.356l-0.02-99.529
					l0-1.355l-1.355,0l-3.177,0l0.131-89.86l0.002-1.358h-1.358h-16.946l0.01-39.687l16.805,0.004l1.356,0v-1.356v-138.894v-1.355
					l-1.355,0l-16.811-0.005l0.012-40.795l16.807,0.005l1.356,0v-1.356v-89.875l3.186,0l1.356,0v-1.356l-0.002-99.535v-1.356h-1.356
					l-24.759-0.001l0.001-42.925l0-1.356h-1.356l-92.721-0.003l-1.356,0v1.356v5.781l-86.594,0.048l-0.003-5.771l-0.001-1.354
					l-1.354-0.001l-92.706-0.064L678.792,1191.506L678.792,1191.506z"
            />
          </g>
        </g>
        <g>
          <path d="M750.608,1507.107h16.747v2.43h-14.117v8.324h12.619v2.397h-12.619v10.155h-2.63V1507.107z" />
          <path
            d="M772.081,1520.658v-13.551h2.63v13.384c0,5.027,2.697,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.691v-13.551h2.63
				v13.351c0,6.792-3.896,10.321-9.722,10.321C776.01,1530.78,772.081,1527.25,772.081,1520.658z"
          />
          <path d="M803.876,1509.538h-7.824v-2.43h18.312v2.43h-7.824v20.876h-2.664V1509.538z" />
          <path
            d="M818.924,1520.658v-13.551h2.63v13.384c0,5.027,2.697,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.691v-13.551h2.63
				v13.351c0,6.792-3.896,10.321-9.722,10.321C822.853,1530.78,818.924,1527.25,818.924,1520.658z"
          />
          <path
            d="M844.56,1507.107h10.021c2.863,0,5.161,0.866,6.625,2.331c1.132,1.132,1.798,2.763,1.798,4.595v0.066
				c0,3.862-2.664,6.126-6.326,6.792l7.158,9.522h-3.229l-6.759-9.056h-6.659v9.056h-2.63V1507.107z M854.348,1518.994
				c3.496,0,5.993-1.798,5.993-4.794v-0.067c0-2.863-2.197-4.595-5.959-4.595h-7.192v9.456H854.348z"
          />
          <path d="M868.63,1507.107h16.847v2.397H871.26v7.958h12.719v2.397H871.26v8.157h14.383v2.397H868.63V1507.107z" />
          <path
            d="M741.402,1547.059h10.021c2.863,0,5.161,0.866,6.625,2.331c1.132,1.132,1.798,2.763,1.798,4.595v0.066
				c0,3.862-2.664,6.126-6.326,6.792l7.158,9.522h-3.229l-6.759-9.056h-6.659v9.056h-2.63V1547.059z M751.19,1558.945
				c3.496,0,5.993-1.798,5.993-4.794v-0.067c0-2.863-2.197-4.595-5.959-4.595h-7.192v9.456H751.19z"
          />
          <path d="M765.473,1547.059h16.847v2.397h-14.217v7.958h12.719v2.397h-12.719v8.157h14.383v2.397h-17.013V1547.059z" />
          <path d="M787.779,1547.059h2.63v20.875h13.118v2.431h-15.748V1547.059z" />
          <path d="M808.387,1547.059h16.847v2.397h-14.217v7.958h12.719v2.397h-12.719v8.157H825.4v2.397h-17.013V1547.059z" />
          <path
            d="M839.316,1546.893h2.464l10.621,23.473h-2.83l-2.73-6.16h-12.686l-2.764,6.16h-2.697L839.316,1546.893z
				 M845.809,1561.809l-5.294-11.853l-5.327,11.853H845.809z"
          />
          <path
            d="M854.997,1566.969l1.631-1.931c2.431,2.197,4.761,3.296,7.991,3.296c3.13,0,5.194-1.665,5.194-3.962v-0.066
				c0-2.164-1.165-3.396-6.06-4.428c-5.36-1.166-7.824-2.896-7.824-6.726v-0.066c0-3.663,3.229-6.359,7.658-6.359
				c3.396,0,5.827,0.965,8.19,2.863l-1.531,2.031c-2.164-1.765-4.329-2.53-6.726-2.53c-3.03,0-4.961,1.665-4.961,3.762v0.067
				c0,2.197,1.199,3.429,6.326,4.528c5.194,1.132,7.591,3.03,7.591,6.592v0.067c0,3.995-3.329,6.592-7.957,6.592
				C860.823,1570.698,857.793,1569.466,854.997,1566.969z"
          />
          <path d="M877.836,1547.059h16.847v2.397h-14.217v7.958h12.719v2.397h-12.719v8.157h14.383v2.397h-17.013V1547.059z" />
        </g>
      </g>
      <g>
        <g>
          <g>
            <polygon
              fill="#CFD0CE"
              points="1216.514,2454.401 1165.408,2454.401 1165.409,2436.235 1082.374,2436.173 1082.399,2454.204
					944.771,2454.402 944.763,2436.235 861.721,2436.235 861.721,2454.403 810.629,2454.401 810.636,2468.026 709.743,2468.026
					709.737,2441.912 665.456,2441.883 665.518,2347.821 672.645,2347.821 672.644,2259.868 665.457,2259.868 665.457,2165.791
					709.737,2165.791 709.736,2139.677 810.627,2139.672 810.627,2144.214 901.858,2144.219 901.858,2162.373 944.004,2162.385
					944.009,2144.219 1084.258,2144.222 1084.258,2162.375 1125.299,2162.385 1125.325,2144.082 1216.515,2144.212
					1216.515,2139.678 1317.399,2139.678 1317.399,2165.791 1361.685,2165.791 1361.685,2259.868 1354.496,2259.868
					1354.497,2347.821 1361.685,2347.821 1361.687,2441.912 1317.399,2441.912 1317.399,2468.025 1216.514,2468.117 				"
            />
          </g>
          <g>
            <polygon
              fill="#CFD0CE"
              points="710.415,2166.469 710.413,2140.355 809.95,2140.35 809.95,2144.891 901.18,2144.896
					901.181,2163.051 944.682,2163.063 944.686,2144.896 1083.581,2144.9 1083.581,2163.053 1125.976,2163.063 1126.002,2144.76
					1217.193,2144.89 1217.193,2140.355 1316.721,2140.355 1316.721,2166.469 1361.007,2166.469 1361.007,2259.19 1353.818,2259.19
					1353.819,2348.499 1361.007,2348.499 1361.009,2441.234 1316.721,2441.234 1316.721,2467.348 1217.191,2467.439
					1217.191,2453.723 1166.086,2453.723 1166.087,2435.558 1081.695,2435.495 1081.721,2453.527 945.448,2453.723
					945.441,2435.558 861.044,2435.558 861.044,2453.726 809.951,2453.723 809.958,2467.348 710.421,2467.348 710.415,2441.234
					666.134,2441.205 666.195,2348.499 673.322,2348.499 673.322,2259.19 666.134,2259.19 666.134,2166.469 				"
            />
          </g>
          <g>
            <path
              d="M809.95,2140.35v4.542l91.231,0.005l0,18.155l43.501,0.012l0.005-18.166l138.894,0.003v18.154l42.395,0.01l0.026-18.302
					l91.191,0.13v-4.535h99.528v26.114h44.286v92.721h-7.189l0.001,89.309h7.188l0.001,92.735h-44.288v26.114l-99.53,0.09v-13.715
					l-51.105,0l0.001-18.166l-84.392-0.063l0.026,18.033l-136.273,0.196l-0.007-18.165l-84.398,0v18.168l-51.093-0.003l0.007,13.625
					h-99.537l-0.006-26.114l-44.28-0.029l0.061-92.706h7.127l-0.001-89.309h-7.187v-92.721h44.28l-0.001-26.114L809.95,2140.35
					 M811.305,2138.994h-1.356l-99.536,0.005l-1.355,0v1.356l0.001,24.758h-42.925h-1.356v1.356v92.721v1.356h1.356h5.831
					l0.001,86.597h-5.771h-1.355l-0.001,1.355l-0.061,92.706l-0.001,1.355l1.355,0.001l42.926,0.028l0.006,24.76l0,1.355h1.355
					h99.537h1.356l-0.001-1.356l-0.007-12.268l49.736,0.002h1.356v-1.355v-16.813l81.687,0l0.007,16.81l0,1.357l1.357-0.002
					l136.272-0.196l1.356-0.002l-0.002-1.355l-0.024-16.674l81.679,0.061l-0.001,16.811v1.356h1.356h49.75v12.359v1.357l1.357-0.001
					l99.53-0.091l1.354-0.001v-1.354v-24.758h42.932h1.356v-1.356l-0.001-92.735v-1.356h-1.356l-5.833,0l-0.001-86.598h5.834h1.356
					v-1.355v-92.721v-1.356h-1.356h-42.931v-24.758V2139h-1.356h-99.528h-1.356v1.356v3.177l-89.833-0.128l-1.356-0.002
					l-0.002,1.356l-0.024,16.948l-39.686-0.009v-16.798v-1.355h-1.356l-138.894-0.003l-1.355,0l0,1.355l-0.005,16.811l-40.79-0.011
					l0-16.799v-1.355l-1.356,0l-89.875-0.005v-3.186V2138.994L811.305,2138.994z"
            />
          </g>
        </g>
        <g>
          <path d="M946.706,2270.699h16.747v2.431h-14.117v8.324h12.619v2.396h-12.619v10.155h-2.63V2270.699z" />
          <path
            d="M968.18,2284.251v-13.552h2.63v13.385c0,5.027,2.697,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.69v-13.552h2.63
				v13.352c0,6.792-3.896,10.321-9.722,10.321C972.108,2294.372,968.18,2290.843,968.18,2284.251z"
          />
          <path d="M999.974,2273.13h-7.824v-2.431h18.312v2.431h-7.824v20.876h-2.664V2273.13z" />
          <path
            d="M1015.022,2284.251v-13.552h2.63v13.385c0,5.027,2.697,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.69v-13.552h2.63
				v13.352c0,6.792-3.896,10.321-9.722,10.321C1018.951,2294.372,1015.022,2290.843,1015.022,2284.251z"
          />
          <path
            d="M1040.658,2270.699h10.021c2.863,0,5.161,0.866,6.625,2.331c1.132,1.132,1.798,2.764,1.798,4.595v0.066
				c0,3.862-2.664,6.126-6.326,6.792l7.158,9.522h-3.229l-6.759-9.057h-6.659v9.057h-2.63V2270.699z M1050.446,2282.586
				c3.496,0,5.993-1.798,5.993-4.795v-0.066c0-2.863-2.197-4.595-5.959-4.595h-7.192v9.456H1050.446z"
          />
          <path d="M1064.729,2270.699h16.847v2.397h-14.217v7.958h12.719v2.396h-12.719v8.157h14.383v2.397h-17.013V2270.699z" />
          <path
            d="M937.5,2310.65h10.021c2.863,0,5.161,0.866,6.625,2.331c1.132,1.132,1.798,2.764,1.798,4.595v0.066
				c0,3.862-2.664,6.126-6.326,6.792l7.158,9.522h-3.229l-6.759-9.057h-6.659v9.057h-2.63V2310.65z M947.289,2322.537
				c3.496,0,5.993-1.798,5.993-4.795v-0.066c0-2.863-2.197-4.595-5.959-4.595h-7.192v9.456H947.289z"
          />
          <path d="M961.571,2310.65h16.847v2.397h-14.217v7.958h12.719v2.396h-12.719v8.157h14.383v2.397h-17.013V2310.65z" />
          <path d="M983.877,2310.65h2.63v20.876h13.118v2.431h-15.748V2310.65z" />
          <path d="M1004.485,2310.65h16.847v2.397h-14.217v7.958h12.719v2.396h-12.719v8.157h14.383v2.397h-17.013V2310.65z" />
          <path
            d="M1035.414,2310.484h2.464l10.621,23.473h-2.83l-2.73-6.159h-12.685l-2.763,6.159h-2.697L1035.414,2310.484z
				 M1041.907,2325.4l-5.294-11.853l-5.327,11.853H1041.907z"
          />
          <path
            d="M1051.095,2330.561l1.631-1.931c2.431,2.197,4.761,3.296,7.991,3.296c3.13,0,5.194-1.664,5.194-3.962v-0.066
				c0-2.164-1.165-3.396-6.06-4.429c-5.36-1.165-7.824-2.896-7.824-6.725v-0.067c0-3.662,3.229-6.359,7.658-6.359
				c3.396,0,5.827,0.966,8.19,2.864l-1.531,2.03c-2.164-1.765-4.329-2.53-6.726-2.53c-3.03,0-4.961,1.665-4.961,3.763v0.066
				c0,2.197,1.199,3.43,6.326,4.528c5.194,1.132,7.591,3.029,7.591,6.592v0.066c0,3.996-3.329,6.593-7.957,6.593
				C1056.921,2334.29,1053.892,2333.058,1051.095,2330.561z"
          />
          <path d="M1073.934,2310.65h16.847v2.397h-14.217v7.958h12.719v2.396h-12.719v8.157h14.383v2.397h-17.013V2310.65z" />
        </g>
      </g>
      <g>
        <g>
          <g>
            <polygon
              fill="#CFD0CE"
              points="2138.458,2468.026 2138.446,2454.401 2087.353,2454.401 2087.354,2436.235 2004.316,2436.173
					2004.303,2454.206 1866.711,2454.104 1866.708,2436.235 1783.666,2436.253 1783.665,2454.403 1732.573,2454.401
					1732.581,2468.026 1631.688,2468.026 1631.681,2441.912 1587.401,2441.883 1587.346,2347.821 1594.588,2347.817
					1594.537,2259.868 1587.401,2259.868 1587.401,2165.791 1631.681,2165.791 1631.681,2139.677 1732.572,2139.672
					1732.571,2144.214 1823.802,2144.219 1823.803,2162.373 1865.948,2162.374 1865.948,2144.219 2006.203,2144.222
					2006.198,2162.375 2047.243,2162.375 2047.269,2144.083 2138.459,2144.082 2138.459,2139.678 2239.343,2139.678
					2239.349,2165.791 2283.629,2165.781 2283.629,2259.868 2276.44,2259.868 2276.441,2347.821 2283.629,2347.821
					2283.629,2441.912 2239.343,2441.912 2239.343,2468.026 				"
            />
          </g>
          <g>
            <polygon
              fill="#CFD0CE"
              points="1588.079,2166.469 1632.359,2166.469 1632.359,2140.355 1731.894,2140.349 1731.894,2144.891
					1823.125,2144.896 1823.125,2163.051 1866.626,2163.052 1866.626,2144.896 2005.525,2144.9 2005.521,2163.053 2047.92,2163.053
					2047.946,2144.76 2139.137,2144.76 2139.137,2140.355 2238.665,2140.355 2238.671,2166.469 2282.952,2166.459 2282.952,2259.19
					2275.762,2259.19 2275.764,2348.499 2282.952,2348.499 2282.952,2441.234 2238.665,2441.234 2238.665,2467.348
					2139.135,2467.348 2139.123,2453.723 2088.03,2453.723 2088.031,2435.558 2003.639,2435.495 2003.626,2453.527
					1867.389,2453.425 1867.385,2435.558 1782.988,2435.575 1782.987,2453.726 1731.895,2453.723 1731.902,2467.348
					1632.365,2467.348 1632.359,2441.234 1588.079,2441.205 1588.024,2348.499 1595.267,2348.495 1595.214,2259.19
					1588.079,2259.19 				"
            />
          </g>
          <g>
            <path
              d="M1731.894,2140.349l0,4.542l91.231,0.005l0,18.155l43.501,0.001l0-18.155l138.899,0.003l-0.004,18.153h42.399
					l0.026-18.292l91.191,0v-4.405h99.528l0.006,26.114l44.28-0.01v92.732h-7.189l0.001,89.309h7.188v92.735h-44.286v26.114h-99.53
					l-0.012-13.625l-51.093,0l0.001-18.166l-84.392-0.063l-0.014,18.033l-136.237-0.102l-0.004-17.867l-84.398,0.017l-0.001,18.151
					l-51.092-0.003l0.007,13.625h-99.537l-0.006-26.114l-44.28-0.029l-0.054-92.706l7.242-0.004l-0.052-89.305h-7.136v-92.721h44.28
					v-26.114L1731.894,2140.349 M1733.25,2138.994h-1.356l-99.535,0.005l-1.355,0v1.355v24.759h-42.925h-1.356v1.356v92.721v1.356
					h1.356h5.781l0.051,86.594l-5.887,0.003l-1.356,0.001l0.001,1.356l0.054,92.706l0.001,1.354l1.354,0.001l42.926,0.028
					l0.006,24.76l0,1.355h1.355h99.537h1.356l-0.001-1.356l-0.007-12.268l49.735,0.002h1.356v-1.355l0.001-16.796l81.687-0.016
					l0.003,16.511l0,1.354l1.354,0.001l136.237,0.102l1.356,0.001l0.001-1.355l0.013-16.677l81.682,0.061l-0.001,16.811l0,1.356
					h1.356h49.739l0.011,12.27l0.001,1.354h1.354h99.53h1.356v-1.355v-24.758h42.931h1.356v-1.356v-92.735v-1.355h-1.356h-5.833
					l-0.001-86.598h5.834h1.356v-1.355v-92.732v-1.356l-1.356,0l-42.925,0.01l-0.006-24.758l0-1.355h-1.355h-99.528h-1.356v1.356
					v3.049l-89.835,0h-1.354l-0.002,1.354l-0.024,16.939h-39.689l0.004-16.797l0-1.356h-1.356l-138.899-0.003l-1.356,0v1.356l0,16.8
					l-40.79-0.001l0-16.799v-1.355l-1.355,0l-89.875-0.005l0-3.186L1733.25,2138.994L1733.25,2138.994z"
            />
          </g>
        </g>
        <g>
          <path d="M1868.621,2270.699h16.747v2.431h-14.117v8.324h12.619v2.396h-12.619v10.155h-2.63V2270.699z" />
          <path
            d="M1890.094,2284.251v-13.552h2.63v13.385c0,5.027,2.697,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.69v-13.552h2.63
				v13.352c0,6.792-3.896,10.321-9.722,10.321C1894.023,2294.372,1890.094,2290.843,1890.094,2284.251z"
          />
          <path d="M1921.889,2273.13h-7.824v-2.431h18.312v2.431h-7.824v20.876h-2.664V2273.13z" />
          <path
            d="M1936.937,2284.251v-13.552h2.63v13.385c0,5.027,2.697,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.69v-13.552h2.63
				v13.352c0,6.792-3.896,10.321-9.722,10.321C1940.866,2294.372,1936.937,2290.843,1936.937,2284.251z"
          />
          <path
            d="M1962.572,2270.699h10.021c2.863,0,5.161,0.866,6.625,2.331c1.132,1.132,1.798,2.764,1.798,4.595v0.066
				c0,3.862-2.664,6.126-6.326,6.792l7.158,9.522h-3.229l-6.759-9.057h-6.659v9.057h-2.63V2270.699z M1972.361,2282.586
				c3.496,0,5.993-1.798,5.993-4.795v-0.066c0-2.863-2.197-4.595-5.959-4.595h-7.192v9.456H1972.361z"
          />
          <path d="M1986.643,2270.699h16.847v2.397h-14.217v7.958h12.719v2.396h-12.719v8.157h14.383v2.397h-17.013V2270.699z" />
          <path
            d="M1859.415,2310.65h10.021c2.863,0,5.161,0.866,6.625,2.331c1.132,1.132,1.798,2.764,1.798,4.595v0.066
				c0,3.862-2.664,6.126-6.326,6.792l7.158,9.522h-3.229l-6.759-9.057h-6.659v9.057h-2.63V2310.65z M1869.203,2322.537
				c3.496,0,5.993-1.798,5.993-4.795v-0.066c0-2.863-2.197-4.595-5.959-4.595h-7.192v9.456H1869.203z"
          />
          <path d="M1883.485,2310.65h16.847v2.397h-14.217v7.958h12.719v2.396h-12.719v8.157h14.383v2.397h-17.013V2310.65z" />
          <path d="M1905.792,2310.65h2.63v20.876h13.118v2.431h-15.748V2310.65z" />
          <path d="M1926.399,2310.65h16.847v2.397h-14.217v7.958h12.719v2.396h-12.719v8.157h14.383v2.397h-17.013V2310.65z" />
          <path
            d="M1957.329,2310.484h2.464l10.621,23.473h-2.83l-2.73-6.159h-12.686l-2.764,6.159h-2.697L1957.329,2310.484z
				 M1963.821,2325.4l-5.294-11.853l-5.327,11.853H1963.821z"
          />
          <path
            d="M1973.009,2330.561l1.631-1.931c2.431,2.197,4.761,3.296,7.991,3.296c3.13,0,5.194-1.664,5.194-3.962v-0.066
				c0-2.164-1.165-3.396-6.06-4.429c-5.36-1.165-7.824-2.896-7.824-6.725v-0.067c0-3.662,3.229-6.359,7.658-6.359
				c3.396,0,5.827,0.966,8.19,2.864l-1.531,2.03c-2.164-1.765-4.329-2.53-6.726-2.53c-3.03,0-4.961,1.665-4.961,3.763v0.066
				c0,2.197,1.199,3.43,6.326,4.528c5.194,1.132,7.591,3.029,7.591,6.592v0.066c0,3.996-3.329,6.593-7.957,6.593
				C1978.836,2334.29,1975.806,2333.058,1973.009,2330.561z"
          />
          <path d="M1995.849,2310.65h16.847v2.397h-14.217v7.958h12.719v2.396h-12.719v8.157h14.383v2.397h-17.013V2310.65z" />
        </g>
      </g>
      <g>
        <g>
          <g>
            <polygon
              fill="#CFD0CE"
              points="3048.084,2384.023 3048.084,2370.686 2996.034,2370.686 2996.034,2351.492 2950.489,2351.492
					2950.489,2342.057 2917.735,2342.057 2917.735,2370.686 2822.693,2370.686 2822.693,2353.118 2776.493,2353.118
					2776.493,2370.198 2723.792,2370.198 2723.792,2384.023 2627.448,2384.023 2627.448,2357.511 2583.692,2357.511
					2583.692,2264.745 2591.094,2264.745 2591.094,2175.169 2583.692,2175.169 2583.692,2082.403 2628.261,2082.403
					2628.261,2055.89 2723.466,2055.89 2723.466,2069.554 2836.677,2069.554 2836.677,2086.146 2936.338,2086.146
					2936.338,2069.065 3047.922,2069.065 3047.922,2055.89 3144.266,2055.89 3144.266,2082.078 3189.159,2082.078
					3189.159,2175.169 3182.761,2175.169 3182.761,2264.258 3189.159,2264.258 3189.159,2357.673 3144.428,2357.673
					3144.428,2384.023 				"
            />
            <path
              d="M3143.59,2056.566v26.188h44.894v91.74h-6.398v90.438h6.398v92.065h-44.731v26.351h-94.993v-13.338h-52.051v-19.194
					h-45.545v-9.434h-34.104v28.628h-93.692v-17.567h-47.551v17.079h-52.701v13.826h-94.993v-26.513h-43.755v-91.415h7.401v-90.927
					h-7.401v-91.414h44.569v-26.513h93.854v13.663h113.211v16.591h101.011v-17.079h111.584v-13.175H3143.59 M3144.941,2055.215
					h-1.351h-94.993h-1.351v1.351v11.825h-110.234h-1.351v1.351v15.729h-98.31v-15.24v-1.351h-1.351h-111.86v-12.312v-1.351h-1.351
					h-93.854h-1.351v1.351v25.163h-43.218h-1.351v1.351v91.414v1.351h1.351h6.05v88.225h-6.05h-1.351v1.351v91.415v1.351h1.351
					h42.405v25.163v1.351h1.351h94.993h1.351v-1.351v-12.476h51.351h1.351v-1.351v-15.729h44.849v16.217v1.351h1.351h93.692h1.351
					v-1.351v-27.277h31.403v8.084v1.351h1.351h44.194v17.843v1.351h1.351h50.7v11.988v1.351h1.351h94.993h1.351v-1.351v-25h43.381
					h1.351v-1.351v-92.065v-1.351h-1.351h-5.047v-87.737h5.047h1.351v-1.351v-91.74v-1.351h-1.351h-43.543v-24.837V2055.215
					L3144.941,2055.215z"
            />
          </g>
        </g>
        <g>
          <path d="M2819.455,2186.716h16.77v2.434h-14.136v8.335h12.636v2.4h-12.636v10.169h-2.634V2186.716z" />
          <path
            d="M2840.962,2200.285v-13.569h2.634v13.402c0,5.034,2.701,7.868,7.135,7.868c4.301,0,7.035-2.601,7.035-7.701v-13.569
				h2.634v13.369c0,6.802-3.901,10.336-9.735,10.336C2844.896,2210.421,2840.962,2206.887,2840.962,2200.285z"
          />
          <path d="M2872.805,2189.149h-7.835v-2.434h18.337v2.434h-7.835v20.904h-2.667V2189.149z" />
          <path
            d="M2887.877,2200.285v-13.569h2.634v13.402c0,5.034,2.701,7.868,7.135,7.868c4.301,0,7.035-2.601,7.035-7.701v-13.569
				h2.634v13.369c0,6.802-3.901,10.336-9.735,10.336C2891.811,2210.421,2887.877,2206.887,2887.877,2200.285z"
          />
          <path
            d="M2913.551,2186.716h10.036c2.867,0,5.167,0.866,6.634,2.333c1.134,1.134,1.801,2.768,1.801,4.602v0.066
				c0,3.867-2.667,6.135-6.335,6.802l7.168,9.535h-3.234l-6.768-9.068h-6.668v9.068h-2.634V2186.716z M2923.354,2198.618
				c3.5,0,6.001-1.801,6.001-4.801v-0.067c0-2.867-2.2-4.601-5.968-4.601h-7.202v9.469H2923.354z"
          />
          <path d="M2937.659,2186.716h16.87v2.4h-14.236v7.968h12.736v2.4h-12.736v8.169h14.403v2.4h-17.037V2186.716z" />
          <path
            d="M2810.235,2226.729h10.036c2.867,0,5.167,0.866,6.634,2.333c1.134,1.134,1.801,2.768,1.801,4.602v0.066
				c0,3.867-2.667,6.135-6.335,6.802l7.168,9.535h-3.234l-6.768-9.068h-6.668v9.068h-2.634V2226.729z M2820.037,2238.632
				c3.5,0,6.001-1.801,6.001-4.801v-0.067c0-2.867-2.2-4.601-5.968-4.601h-7.202v9.469H2820.037z"
          />
          <path d="M2834.343,2226.729h16.87v2.4h-14.236v7.968h12.736v2.4h-12.736v8.169h14.403v2.4h-17.037V2226.729z" />
          <path d="M2856.683,2226.729h2.634v20.904h13.136v2.434h-15.77V2226.729z" />
          <path d="M2877.323,2226.729h16.87v2.4h-14.236v7.968h12.736v2.4h-12.736v8.169h14.403v2.4h-17.037V2226.729z" />
          <path
            d="M2908.299,2226.562h2.467l10.636,23.505h-2.834l-2.734-6.168h-12.703l-2.767,6.168h-2.701L2908.299,2226.562z
				 M2914.8,2241.499l-5.301-11.869l-5.334,11.869H2914.8z"
          />
          <path
            d="M2924.005,2246.667l1.634-1.935c2.434,2.201,4.768,3.301,8.001,3.301c3.134,0,5.201-1.667,5.201-3.967v-0.067
				c0-2.167-1.167-3.4-6.068-4.434c-5.368-1.167-7.835-2.901-7.835-6.735v-0.066c0-3.667,3.234-6.368,7.668-6.368
				c3.401,0,5.835,0.967,8.202,2.867l-1.534,2.034c-2.167-1.768-4.334-2.534-6.735-2.534c-3.034,0-4.968,1.667-4.968,3.768v0.066
				c0,2.201,1.2,3.435,6.334,4.534c5.201,1.134,7.602,3.034,7.602,6.602v0.067c0,4-3.334,6.601-7.969,6.601
				C2929.839,2250.4,2926.806,2249.167,2924.005,2246.667z"
          />
          <path d="M2946.879,2226.729h16.87v2.4h-14.236v7.968h12.736v2.4h-12.736v8.169h14.403v2.4h-17.037V2226.729z" />
        </g>
      </g>
      <g id="condo-building-1">
        <polygon
          fill="#8C99A1"
          stroke="#000000"
          strokeWidth="3.8159"
          strokeMiterlimit="10"
          points="2565.567,660.49 2609.844,660.49
			2609.85,634.376 2709.381,634.376 2709.415,648.001 2760.473,647.872 2760.473,666.167 2844.87,666.167 2844.87,648
			2981.114,648.001 2981.124,666.167 3065.515,666.167 3065.515,648.001 3116.608,648.001 3116.608,634.376 3216.15,634.376
			3216.15,660.49 3260.437,660.49 3260.437,753.225 3253.249,753.225 3253.247,842.534 3260.437,842.534 3260.437,935.256
			3216.156,935.256 3216.156,961.37 3116.622,961.37 3116.622,956.833 3025.405,956.834 3025.401,938.662 2982.996,938.701
			2983.012,956.828 2844.114,956.849 2844.114,938.674 2800.61,938.674 2800.61,956.828 2709.379,956.828 2709.379,961.37
			2609.851,961.37 2609.851,935.256 2565.564,935.256 2565.562,842.534 2572.752,842.534 2572.752,753.23 2565.577,753.219 		"
        />
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M2817.312,830.35l3.333-3.991c2.307,1.904,4.724,3.112,7.653,3.112c2.308,0,3.699-0.915,3.699-2.417
					v-0.073c0-1.429-0.879-2.161-5.164-3.259c-5.163-1.318-8.496-2.747-8.496-7.838v-0.072c0-4.651,3.735-7.728,8.973-7.728
					c3.735,0,6.921,1.172,9.521,3.259l-2.93,4.248c-2.271-1.574-4.505-2.526-6.665-2.526c-2.161,0-3.296,0.989-3.296,2.233v0.074
					c0,1.684,1.099,2.233,5.529,3.369c5.2,1.354,8.13,3.223,8.13,7.689v0.074c0,5.09-3.882,7.946-9.411,7.946
					C2824.308,834.452,2820.389,833.097,2817.312,830.35z"
            />
            <path
              fill="#FFFFFF"
              d="M2841.374,821.341v-0.073c0-7.288,5.749-13.257,13.659-13.257s13.587,5.896,13.587,13.184v0.073
					c0,7.288-5.75,13.257-13.66,13.257S2841.374,828.628,2841.374,821.341z M2862.724,821.341v-0.073
					c0-4.395-3.223-8.057-7.764-8.057s-7.69,3.589-7.69,7.983v0.073c0,4.395,3.223,8.057,7.764,8.057
					S2862.724,825.736,2862.724,821.341z"
            />
            <path
              fill="#FFFFFF"
              d="M2873.638,808.451h5.64v20.508h12.78v5.127h-18.42V808.451z"
            />
            <path
              fill="#FFFFFF"
              d="M2896.307,808.451h9.997c8.057,0,13.623,5.529,13.623,12.744v0.073c0,7.214-5.566,12.817-13.623,12.817
					h-9.997V808.451z M2901.946,813.541v15.455h4.357c4.614,0,7.728-3.113,7.728-7.654v-0.073c0-4.541-3.113-7.728-7.728-7.728
					H2901.946z"
            />
            <path
              fill="#FFFFFF"
              d="M2934.796,821.341v-0.073c0-7.288,5.749-13.257,13.659-13.257s13.587,5.896,13.587,13.184v0.073
					c0,7.288-5.75,13.257-13.66,13.257S2934.796,828.628,2934.796,821.341z M2956.146,821.341v-0.073
					c0-4.395-3.223-8.057-7.764-8.057s-7.69,3.589-7.69,7.983v0.073c0,4.395,3.223,8.057,7.764,8.057
					S2956.146,825.736,2956.146,821.341z"
            />
            <path
              fill="#FFFFFF"
              d="M2966.657,823.099v-14.648h5.64v14.502c0,4.175,2.087,6.336,5.529,6.336s5.53-2.088,5.53-6.152v-14.686
					h5.64v14.465c0,7.764-4.358,11.572-11.243,11.572S2966.657,830.643,2966.657,823.099z"
            />
            <path
              fill="#FFFFFF"
              d="M3000.716,813.651h-7.801v-5.2h21.24v5.2h-7.8v20.435h-5.64V813.651z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M2824.178,766.36h9.921c2.664,0,4.762,0.767,6.094,2.064c0.965,0.999,1.498,2.23,1.498,3.729v0.066
					c0,3.029-1.864,4.595-3.696,5.394c2.764,0.832,4.994,2.431,4.994,5.627v0.066c0,3.995-3.362,6.359-8.456,6.359h-10.354V766.36z
					 M2839.026,772.521c0-2.298-1.831-3.796-5.16-3.796h-7.092v8.024h6.892c3.163,0,5.36-1.432,5.36-4.162V772.521z
					 M2834.133,779.079h-7.358v8.224h7.824c3.529,0,5.727-1.564,5.727-4.161v-0.067
					C2840.325,780.544,2838.194,779.079,2834.133,779.079z"
            />
            <path
              fill="#FFFFFF"
              d="M2847.848,779.912V766.36h2.631v13.385c0,5.027,2.696,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.69
					V766.36h2.63v13.352c0,6.792-3.896,10.321-9.722,10.321C2851.777,790.033,2847.848,786.504,2847.848,779.912z"
            />
            <path
              fill="#FFFFFF"
              d="M2873.717,766.36h2.63v23.307h-2.63V766.36z"
            />
            <path
              fill="#FFFFFF"
              d="M2883.171,766.36h2.63v20.876h13.118v2.431h-15.748V766.36z"
            />
            <path
              fill="#FFFFFF"
              d="M2903.779,766.36h8.091c7.324,0,12.386,5.028,12.386,11.587v0.066c0,6.56-5.062,11.653-12.386,11.653
					h-8.091V766.36z M2906.409,768.791v18.445h5.461c5.893,0,9.655-3.995,9.655-9.156v-0.066c0-5.16-3.763-9.223-9.655-9.223
					H2906.409z"
            />
            <path
              fill="#FFFFFF"
              d="M2930.047,766.36h2.631v23.307h-2.631V766.36z"
            />
            <path
              fill="#FFFFFF"
              d="M2939.502,766.36h2.464l14.683,18.679V766.36h2.564v23.307h-2.098l-15.05-19.111v19.111h-2.563V766.36z"
            />
            <path
              fill="#FFFFFF"
              d="M2964.771,778.08v-0.066c0-6.393,4.694-12.053,11.687-12.053c3.929,0,6.358,1.132,8.689,3.097
					l-1.698,1.998c-1.798-1.565-3.795-2.664-7.092-2.664c-5.16,0-8.856,4.361-8.856,9.556v0.066c0,5.561,3.562,9.688,9.223,9.688
					c2.664,0,5.128-1.031,6.726-2.33v-5.793h-7.092v-2.364h9.622v9.289c-2.164,1.932-5.427,3.562-9.355,3.562
					C2969.299,790.066,2964.771,784.739,2964.771,778.08z"
            />
            <path
              fill="#FFFFFF"
              d="M3004.556,768.925l-4.395,1.364l-0.633-2.131l5.693-1.964h1.931v23.473h-2.597V768.925z"
            />
          </g>
        </g>
      </g>
      <g id="condo-building-2">
        <polygon
          fill="#8C99A1"
          stroke="#000000"
          strokeWidth="3.8159"
          strokeMiterlimit="10"
          points="2530.48,1348.166
			2646.669,1348.162 2646.669,1343.625 2740.573,1343.626 2740.534,1361.791 2828.342,1361.794 2828.341,1343.619 2975.945,1343.62
			2975.945,1361.799 3019.851,1361.791 3019.846,1343.625 3111.045,1343.625 3111.045,1330 3219.291,1329.999 3219.291,1348.165
			3257.889,1348.166 3257.889,1435.213 3238.966,1435.213 3238.966,1477.98 3256.932,1477.98 3257.888,1581.679 3238.966,1581.679
			3238.966,1624.445 3257.889,1624.445 3257.889,1711.493 3219.291,1711.493 3219.291,1729.66 3111.045,1729.658 3111.045,1626.717
			3076.605,1626.716 3076.604,1652.549 2979.339,1652.549 2979.339,1634.286 2936.573,1634.286 2936.573,1652.452
			2832.873,1652.452 2832.873,1634.286 2790.107,1634.286 2790.104,1652.452 2692.842,1652.453 2692.841,1626.716
			2658.396,1626.717 2658.395,1666.078 2553.188,1666.076 2553.182,1619.148 2510.043,1619.147 2510.037,1515.449
			2548.649,1515.447 2548.649,1469.654 2530.483,1469.653 		"
        />
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M2785.578,1521.678l3.333-3.991c2.307,1.904,4.724,3.112,7.654,3.112c2.307,0,3.699-0.915,3.699-2.417
					v-0.073c0-1.429-0.879-2.161-5.164-3.259c-5.164-1.318-8.496-2.747-8.496-7.838v-0.072c0-4.651,3.735-7.728,8.972-7.728
					c3.735,0,6.921,1.172,9.521,3.259l-2.93,4.248c-2.271-1.574-4.504-2.526-6.665-2.526s-3.296,0.989-3.296,2.233v0.074
					c0,1.684,1.099,2.233,5.53,3.369c5.2,1.354,8.13,3.223,8.13,7.689v0.074c0,5.09-3.882,7.946-9.412,7.946
					C2792.573,1525.779,2788.654,1524.425,2785.578,1521.678z"
            />
            <path
              fill="#FFFFFF"
              d="M2809.639,1512.669v-0.073c0-7.288,5.75-13.257,13.66-13.257s13.586,5.896,13.586,13.184v0.073
					c0,7.288-5.75,13.257-13.66,13.257S2809.639,1519.956,2809.639,1512.669z M2830.989,1512.669v-0.073
					c0-4.395-3.223-8.057-7.764-8.057s-7.69,3.589-7.69,7.983v0.073c0,4.395,3.223,8.057,7.764,8.057
					S2830.989,1517.063,2830.989,1512.669z"
            />
            <path
              fill="#FFFFFF"
              d="M2841.903,1499.778h5.64v20.508h12.781v5.127h-18.42V1499.778z"
            />
            <path
              fill="#FFFFFF"
              d="M2864.572,1499.778h9.998c8.057,0,13.623,5.529,13.623,12.744v0.073c0,7.214-5.566,12.817-13.623,12.817
					h-9.998V1499.778z M2870.211,1504.868v15.455h4.358c4.614,0,7.727-3.113,7.727-7.654v-0.073c0-4.541-3.113-7.728-7.727-7.728
					H2870.211z"
            />
            <path
              fill="#FFFFFF"
              d="M2903.061,1512.669v-0.073c0-7.288,5.75-13.257,13.66-13.257s13.586,5.896,13.586,13.184v0.073
					c0,7.288-5.75,13.257-13.66,13.257S2903.061,1519.956,2903.061,1512.669z M2924.411,1512.669v-0.073
					c0-4.395-3.223-8.057-7.764-8.057s-7.69,3.589-7.69,7.983v0.073c0,4.395,3.223,8.057,7.764,8.057
					S2924.411,1517.063,2924.411,1512.669z"
            />
            <path
              fill="#FFFFFF"
              d="M2934.922,1514.427v-14.648h5.64v14.502c0,4.175,2.087,6.336,5.53,6.336s5.53-2.088,5.53-6.152v-14.686
					h5.64v14.465c0,7.764-4.358,11.572-11.243,11.572S2934.922,1521.971,2934.922,1514.427z"
            />
            <path
              fill="#FFFFFF"
              d="M2968.981,1504.979h-7.8v-5.2h21.24v5.2h-7.8v20.435h-5.64V1504.979z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M2788.431,1457.688h9.921c2.664,0,4.761,0.767,6.093,2.064c0.965,0.999,1.498,2.23,1.498,3.729v0.066
					c0,3.029-1.864,4.595-3.696,5.394c2.764,0.832,4.994,2.431,4.994,5.627v0.066c0,3.995-3.362,6.359-8.457,6.359h-10.354V1457.688
					z M2803.28,1463.848c0-2.298-1.831-3.796-5.161-3.796h-7.092v8.024h6.892c3.163,0,5.36-1.432,5.36-4.162V1463.848z
					 M2798.386,1470.407h-7.358v8.224h7.824c3.529,0,5.727-1.564,5.727-4.161v-0.067
					C2804.579,1471.872,2802.448,1470.407,2798.386,1470.407z"
            />
            <path
              fill="#FFFFFF"
              d="M2812.102,1471.24v-13.552h2.63v13.385c0,5.027,2.697,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.69
					v-13.552h2.63v13.352c0,6.792-3.896,10.321-9.722,10.321C2816.031,1481.361,2812.102,1477.832,2812.102,1471.24z"
            />
            <path
              fill="#FFFFFF"
              d="M2837.97,1457.688h2.63v23.307h-2.63V1457.688z"
            />
            <path
              fill="#FFFFFF"
              d="M2847.425,1457.688h2.63v20.876h13.118v2.431h-15.748V1457.688z"
            />
            <path
              fill="#FFFFFF"
              d="M2868.033,1457.688h8.09c7.325,0,12.386,5.028,12.386,11.587v0.066c0,6.56-5.061,11.653-12.386,11.653
					h-8.09V1457.688z M2870.663,1460.119v18.445h5.46c5.893,0,9.656-3.995,9.656-9.156v-0.066c0-5.16-3.763-9.223-9.656-9.223
					H2870.663z"
            />
            <path
              fill="#FFFFFF"
              d="M2894.301,1457.688h2.63v23.307h-2.63V1457.688z"
            />
            <path
              fill="#FFFFFF"
              d="M2903.756,1457.688h2.463l14.683,18.679v-18.679h2.564v23.307h-2.098l-15.049-19.111v19.111h-2.563
					V1457.688z"
            />
            <path
              fill="#FFFFFF"
              d="M2929.025,1469.408v-0.066c0-6.393,4.694-12.053,11.687-12.053c3.929,0,6.359,1.132,8.69,3.097
					l-1.698,1.998c-1.798-1.565-3.795-2.664-7.092-2.664c-5.161,0-8.856,4.361-8.856,9.556v0.066c0,5.561,3.562,9.688,9.223,9.688
					c2.664,0,5.127-1.031,6.726-2.33v-5.793h-7.092v-2.364h9.622v9.289c-2.164,1.932-5.427,3.562-9.355,3.562
					C2933.553,1481.394,2929.025,1476.067,2929.025,1469.408z"
            />
            <path
              fill="#FFFFFF"
              d="M2964.448,1478.963l8.457-7.458c3.562-3.196,4.861-4.994,4.861-7.324c0-2.73-2.164-4.462-4.694-4.462
					c-2.664,0-4.395,1.398-6.26,3.995l-1.931-1.398c2.098-3.129,4.295-4.961,8.391-4.961c4.195,0,7.225,2.797,7.225,6.593v0.066
					c0,3.396-1.798,5.494-5.893,9.057l-6.226,5.526h12.353v2.397h-16.281V1478.963z"
            />
          </g>
        </g>
      </g>
      <g id="condo-building-3">
        <polygon
          fill="#8C99A1"
          stroke="#000000"
          strokeWidth="3.8159"
          strokeMiterlimit="10"
          points="1562.679,733.442
			1630.803,733.442 1630.803,643.255 1891.943,643.255 1891.943,665.963 1982.774,665.963 1982.774,643.255 2277.977,643.255
			2277.977,688.615 2312.039,688.615 2312.039,906.722 2277.977,906.722 2277.977,952.082 1982.774,952.082 1982.774,929.374
			1891.943,929.374 1891.943,952.082 1562.679,952.082 		"
        />
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1838.974,824.854l3.333-3.991c2.307,1.904,4.724,3.112,7.654,3.112c2.307,0,3.699-0.915,3.699-2.417
					v-0.073c0-1.429-0.879-2.161-5.164-3.259c-5.164-1.318-8.496-2.747-8.496-7.838v-0.072c0-4.651,3.735-7.728,8.972-7.728
					c3.735,0,6.921,1.172,9.521,3.259l-2.93,4.248c-2.271-1.574-4.504-2.526-6.665-2.526s-3.296,0.989-3.296,2.233v0.074
					c0,1.684,1.099,2.233,5.53,3.369c5.2,1.354,8.13,3.223,8.13,7.689v0.074c0,5.09-3.882,7.946-9.412,7.946
					C1845.968,828.955,1842.05,827.601,1838.974,824.854z"
            />
            <path
              fill="#FFFFFF"
              d="M1863.035,815.845v-0.073c0-7.288,5.75-13.257,13.66-13.257s13.586,5.896,13.586,13.184v0.073
					c0,7.288-5.75,13.257-13.66,13.257S1863.035,823.132,1863.035,815.845z M1884.385,815.845v-0.073
					c0-4.395-3.223-8.057-7.764-8.057s-7.69,3.589-7.69,7.983v0.073c0,4.395,3.223,8.057,7.764,8.057
					S1884.385,820.239,1884.385,815.845z"
            />
            <path
              fill="#FFFFFF"
              d="M1895.298,802.954h5.64v20.508h12.781v5.127h-18.42V802.954z"
            />
            <path
              fill="#FFFFFF"
              d="M1917.967,802.954h9.998c8.057,0,13.623,5.529,13.623,12.744v0.073c0,7.214-5.566,12.817-13.623,12.817
					h-9.998V802.954z M1923.607,808.044v15.455h4.358c4.614,0,7.727-3.113,7.727-7.654v-0.073c0-4.541-3.113-7.728-7.727-7.728
					H1923.607z"
            />
            <path
              fill="#FFFFFF"
              d="M1956.457,815.845v-0.073c0-7.288,5.75-13.257,13.66-13.257s13.586,5.896,13.586,13.184v0.073
					c0,7.288-5.75,13.257-13.66,13.257S1956.457,823.132,1956.457,815.845z M1977.807,815.845v-0.073
					c0-4.395-3.223-8.057-7.764-8.057s-7.69,3.589-7.69,7.983v0.073c0,4.395,3.223,8.057,7.764,8.057
					S1977.807,820.239,1977.807,815.845z"
            />
            <path
              fill="#FFFFFF"
              d="M1988.318,817.603v-14.648h5.64v14.502c0,4.175,2.087,6.336,5.53,6.336s5.53-2.088,5.53-6.152v-14.686
					h5.64v14.465c0,7.764-4.358,11.572-11.243,11.572S1988.318,825.146,1988.318,817.603z"
            />
            <path
              fill="#FFFFFF"
              d="M2022.376,808.154h-7.8v-5.2h21.24v5.2h-7.8v20.435h-5.64V808.154z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1841.594,760.864h9.921c2.664,0,4.761,0.767,6.093,2.064c0.965,0.999,1.498,2.23,1.498,3.729v0.066
					c0,3.029-1.864,4.595-3.696,5.394c2.764,0.832,4.995,2.431,4.995,5.627v0.066c0,3.995-3.363,6.359-8.457,6.359h-10.354V760.864z
					 M1856.443,767.024c0-2.298-1.831-3.796-5.161-3.796h-7.092v8.024h6.892c3.163,0,5.36-1.432,5.36-4.162V767.024z
					 M1851.549,773.583h-7.358v8.224h7.824c3.529,0,5.727-1.564,5.727-4.161v-0.067
					C1857.741,775.047,1855.61,773.583,1851.549,773.583z"
            />
            <path
              fill="#FFFFFF"
              d="M1865.265,774.416v-13.552h2.63v13.385c0,5.027,2.697,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.69
					v-13.552h2.63v13.352c0,6.792-3.896,10.321-9.722,10.321C1869.193,784.537,1865.265,781.007,1865.265,774.416z"
            />
            <path
              fill="#FFFFFF"
              d="M1891.133,760.864h2.63v23.307h-2.63V760.864z"
            />
            <path
              fill="#FFFFFF"
              d="M1900.587,760.864h2.63v20.876h13.118v2.431h-15.748V760.864z"
            />
            <path
              fill="#FFFFFF"
              d="M1921.196,760.864h8.09c7.325,0,12.386,5.028,12.386,11.587v0.066c0,6.56-5.061,11.653-12.386,11.653
					h-8.09V760.864z M1923.826,763.294v18.445h5.46c5.893,0,9.655-3.995,9.655-9.156v-0.066c0-5.16-3.762-9.223-9.655-9.223
					H1923.826z"
            />
            <path
              fill="#FFFFFF"
              d="M1947.463,760.864h2.63v23.307h-2.63V760.864z"
            />
            <path
              fill="#FFFFFF"
              d="M1956.918,760.864h2.463l14.683,18.679v-18.679h2.564v23.307h-2.098l-15.049-19.111v19.111h-2.563
					V760.864z"
            />
            <path
              fill="#FFFFFF"
              d="M1982.188,772.583v-0.066c0-6.393,4.694-12.053,11.687-12.053c3.929,0,6.359,1.132,8.689,3.097
					l-1.698,1.998c-1.798-1.565-3.795-2.664-7.092-2.664c-5.161,0-8.856,4.361-8.856,9.556v0.066c0,5.561,3.562,9.688,9.223,9.688
					c2.664,0,5.127-1.031,6.726-2.33v-5.793h-7.092v-2.364h9.622v9.289c-2.164,1.932-5.427,3.562-9.356,3.562
					C1986.715,784.57,1982.188,779.243,1982.188,772.583z"
            />
            <path
              fill="#FFFFFF"
              d="M2017.677,780.408l1.931-1.697c1.765,2.197,3.929,3.462,6.792,3.462c2.83,0,5.094-1.798,5.094-4.527
					v-0.067c0-2.896-2.697-4.494-6.526-4.494h-1.498l-0.533-1.565l7.358-8.29h-11.387v-2.364h14.883v1.932l-7.325,8.124
					c4.062,0.333,7.691,2.297,7.691,6.559v0.066c0,4.162-3.462,7.025-7.791,7.025
					C2022.471,784.57,2019.575,782.872,2017.677,780.408z"
            />
          </g>
        </g>
      </g>
      <g id="condo-building-4">
        <polyline
          fill="#8C99A1"
          stroke="#000000"
          strokeWidth="3.8159"
          strokeMiterlimit="10"
          points="1361.009,981.457
			1361.009,877.231 1352.926,877.231 1352.926,776.834 1361.009,776.848 1361.009,672.609 1311.236,672.609 1311.236,643.255
			1199.353,643.255 1199.339,658.57 1141.909,658.57 1141.909,678.99 1092.136,678.99 1092.136,689.2 1057.252,689.2
			1057.252,658.57 949.198,658.57 949.184,678.99 901.139,678.99 901.139,658.57 841.994,658.57 841.994,719.829 735.641,719.829
			735.641,849.154 653.949,849.154 653.949,904.471 687.969,904.471 687.969,1004.881 653.949,1004.881 653.962,1112.936
			843.696,1112.936 843.709,998.048 967.916,998.048 967.916,977.628 1075.119,977.628 1075.119,998.048 1199.353,998.048
			1199.353,1010.81 1311.236,1010.81 1311.236,981.457 1360.969,981.457 1361.009,981.457 		"
        />
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M947.59,869.272l3.333-3.991c2.307,1.904,4.724,3.112,7.654,3.112c2.307,0,3.699-0.915,3.699-2.417
					v-0.073c0-1.429-0.879-2.161-5.164-3.259c-5.164-1.318-8.496-2.747-8.496-7.838v-0.072c0-4.651,3.735-7.728,8.972-7.728
					c3.735,0,6.921,1.172,9.521,3.259l-2.93,4.248c-2.271-1.574-4.504-2.526-6.665-2.526s-3.296,0.989-3.296,2.233v0.074
					c0,1.684,1.099,2.233,5.53,3.369c5.2,1.354,8.13,3.223,8.13,7.689v0.074c0,5.09-3.882,7.946-9.412,7.946
					C954.584,873.374,950.666,872.02,947.59,869.272z"
            />
            <path
              fill="#FFFFFF"
              d="M971.651,860.264v-0.073c0-7.288,5.75-13.257,13.66-13.257s13.586,5.896,13.586,13.184v0.073
					c0,7.288-5.75,13.257-13.66,13.257S971.651,867.551,971.651,860.264z M993.001,860.264v-0.073c0-4.395-3.223-8.057-7.764-8.057
					s-7.69,3.589-7.69,7.983v0.073c0,4.395,3.223,8.057,7.764,8.057S993.001,864.658,993.001,860.264z"
            />
            <path
              fill="#FFFFFF"
              d="M1003.915,847.373h5.64v20.508h12.781v5.127h-18.42V847.373z"
            />
            <path
              fill="#FFFFFF"
              d="M1026.583,847.373h9.998c8.057,0,13.623,5.529,13.623,12.744v0.073c0,7.214-5.566,12.817-13.623,12.817
					h-9.998V847.373z M1032.223,852.463v15.455h4.358c4.614,0,7.727-3.113,7.727-7.654v-0.073c0-4.541-3.113-7.728-7.727-7.728
					H1032.223z"
            />
            <path
              fill="#FFFFFF"
              d="M1065.073,860.264v-0.073c0-7.288,5.75-13.257,13.66-13.257s13.586,5.896,13.586,13.184v0.073
					c0,7.288-5.75,13.257-13.66,13.257S1065.073,867.551,1065.073,860.264z M1086.423,860.264v-0.073
					c0-4.395-3.223-8.057-7.764-8.057s-7.69,3.589-7.69,7.983v0.073c0,4.395,3.223,8.057,7.764,8.057
					S1086.423,864.658,1086.423,860.264z"
            />
            <path
              fill="#FFFFFF"
              d="M1096.934,862.021v-14.648h5.64v14.502c0,4.175,2.087,6.336,5.53,6.336s5.53-2.088,5.53-6.152v-14.686
					h5.64v14.465c0,7.764-4.358,11.572-11.243,11.572S1096.934,869.565,1096.934,862.021z"
            />
            <path
              fill="#FFFFFF"
              d="M1130.993,852.573h-7.8v-5.2h21.24v5.2h-7.8v20.435h-5.64V852.573z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M949.244,805.282h9.921c2.664,0,4.761,0.767,6.093,2.064c0.965,0.999,1.498,2.23,1.498,3.729v0.066
					c0,3.029-1.864,4.595-3.696,5.394c2.764,0.832,4.995,2.431,4.995,5.627v0.066c0,3.995-3.363,6.359-8.457,6.359h-10.354V805.282z
					 M964.093,811.442c0-2.298-1.831-3.796-5.161-3.796h-7.092v8.024h6.892c3.163,0,5.36-1.432,5.36-4.162V811.442z
					 M959.199,818.001h-7.358v8.224h7.824c3.529,0,5.727-1.564,5.727-4.161v-0.067C965.392,819.466,963.261,818.001,959.199,818.001
					z"
            />
            <path
              fill="#FFFFFF"
              d="M972.915,818.834v-13.552h2.63v13.385c0,5.027,2.697,7.857,7.125,7.857c4.295,0,7.025-2.597,7.025-7.69
					v-13.552h2.63v13.352c0,6.792-3.896,10.321-9.722,10.321C976.844,828.955,972.915,825.426,972.915,818.834z"
            />
            <path
              fill="#FFFFFF"
              d="M998.783,805.282h2.63v23.307h-2.63V805.282z"
            />
            <path
              fill="#FFFFFF"
              d="M1008.238,805.282h2.63v20.876h13.118v2.431h-15.748V805.282z"
            />
            <path
              fill="#FFFFFF"
              d="M1028.846,805.282h8.09c7.325,0,12.386,5.028,12.386,11.587v0.066c0,6.56-5.061,11.653-12.386,11.653
					h-8.09V805.282z M1031.476,807.713v18.445h5.46c5.893,0,9.655-3.995,9.655-9.156v-0.066c0-5.16-3.762-9.223-9.655-9.223
					H1031.476z"
            />
            <path
              fill="#FFFFFF"
              d="M1055.114,805.282h2.63v23.307h-2.63V805.282z"
            />
            <path
              fill="#FFFFFF"
              d="M1064.569,805.282h2.463l14.683,18.679v-18.679h2.564v23.307h-2.098l-15.049-19.111v19.111h-2.563
					V805.282z"
            />
            <path
              fill="#FFFFFF"
              d="M1089.838,817.002v-0.066c0-6.393,4.694-12.053,11.687-12.053c3.929,0,6.359,1.132,8.689,3.097
					l-1.698,1.998c-1.798-1.565-3.795-2.664-7.092-2.664c-5.161,0-8.856,4.361-8.856,9.556v0.066c0,5.561,3.562,9.688,9.223,9.688
					c2.664,0,5.127-1.031,6.726-2.33v-5.793h-7.092v-2.364h9.622v9.289c-2.164,1.932-5.427,3.562-9.356,3.562
					C1094.366,828.988,1089.838,823.661,1089.838,817.002z"
            />
            <path
              fill="#FFFFFF"
              d="M1138.046,823.062h-12.286l-0.666-1.931l13.185-16.015h2.331v15.748h3.596v2.197h-3.596v5.527h-2.563
					V823.062z M1138.046,820.864v-12.02l-9.789,12.02H1138.046z"
            />
          </g>
        </g>
      </g>
    </g>
  );
};

export default CondoBuildings;
